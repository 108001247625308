import { TableCell, TableRow } from "@mui/material"
import * as TransactionModel from "../../models/transaction";

function Transaction({transaction}) {
    return (
        <TableRow>
            {TransactionModel.TABLE_FIELDS.map(field => {
                return (
                    <TableCell style={{minWidth: 130, maxWidth:400, overflow: 'hidden', textOverflow: 'ellipsis', verticalAlign: 'top' }}>{field.render(transaction)}</TableCell>
                );
            })}
        </TableRow>
    )
}

export default Transaction