import { Component } from "react";
import ShopContext from "./ShopContext";
import logoWhite from "../images/AlphieMoon PFP-01.jpg";
import { getThemeShop } from "../api/shop";
import _ from "lodash";
import shopBG from "../images/moon-shop-bg.jpg";
import logoBig from "../images/shop-logo-big.png";
import moonCoin from "../images/moon_coin.png";
class ShopProvider extends Component {
  state = {
    initailized : false,
    erc20Address : "",
    devWallet : "",
    raffleRole : "",
    configChannel : "",
    textColor: "white",
    title: "Moon Shop",
    company: "WhaleTogether",
    showCompany : true,
    logoUrl: logoWhite,
    coverUrl: null,
    primaryColor: "#FF2079",
    secondaryColor: "#440BD4",
    bgImage: null,
    bgImageOpacity: 1,
    discordLink: "https://discord.gg/whaletogether",
    twitterLink: "https://twitter.com/WhaleTogether",
    loginLogoUrl : logoBig,
    loginBgUrl : shopBG,
    tokenContractAddress : "0x507238B65e91089C7c5aB5c79f4E66Ec58Ea360A",
    tokenCurrency : "$MOON",
    tokenLogo : moonCoin,
    tokenBurnAddress : "0xdEAD000000000000000042069420694206942069",
    tokenSymbol : "MOON",
    tokenDecimal : 18
  };
  constructor() {
    super();
    this.updateShop();
    this.setPrimaryColor = this.setPrimaryColor.bind(this);
    this.setSecondaryColor = this.setSecondaryColor.bind(this);
    this.setTitle = this.setTitle.bind(this);
    this.setCompany = this.setCompany.bind(this);
    this.setLogoUrl = this.setLogoUrl.bind(this);
    this.setCoverUrl = this.setCoverUrl.bind(this);
    this.setDiscordLink = this.setDiscordLink.bind(this);
    this.setTwitterLink = this.setTwitterLink.bind(this);
    this.setLoginLogoUrl = this.setLoginLogoUrl.bind(this);
    this.setLoginBgUrl = this.setLoginBgUrl.bind(this);
    this.setTokenContractAddress = this.setTokenContractAddress.bind(this);
    this.setTokenCurrency = this.setTokenCurrency.bind(this);
    this.setTokenLogo = this.setTokenLogo.bind(this);
    this.setTokenBurnAddress = this.setTokenBurnAddress.bind(this);
    this.setTokenSymbol = this.setTokenSymbol.bind(this);
    this.setTokenDecimal = this.setTokenDecimal.bind(this);
    this.setInitailized = this.setInitailized.bind(this);
    this.setErc20Address = this.setErc20Address.bind(this);
    this.setDevWallet = this.setDevWallet.bind(this);
    this.setRaffleRole = this.setRaffleRole.bind(this);
    this.setConfigChannel = this.setConfigChannel.bind(this);
    this.setShowCompany = this.setShowCompany.bind(this);
    this.updateShop = this.updateShop.bind(this);
  }
  updateShop() {
    let _this = this;
    getThemeShop().then((res) => {
      if (!_.isNull(res.shop)) {
        let shop = res.shop;
        //console.log(shop);
        localStorage.setItem("initailized",true);
        _this.setState({
          initailized : true,
          erc20Address : shop.erc20Address ? shop.erc20Address : "",
          devWallet : shop.devWallet ? shop.devWallet : "",
          raffleRole : shop.raffleRole ? shop.raffleRole : "",
          configChannel : shop.configChannel ? shop.configChannel : "",
          textColor: shop.text_color ? shop.text_color : "white",
          title: shop.title ? shop.title : "Moon Shop",
          company: shop.company ? shop.company : "WhaleTogether",
          showCompany : !_.isUndefined(shop.show_company) ? shop.show_company : true,
          logoUrl: shop.logo_url ? shop.logo_url : logoWhite,
          coverUrl: shop.cover_url ? shop.cover_url : null,
          primaryColor: shop.primary_color ? shop.primary_color : "#FF2079",
          secondaryColor: shop.secondary_color
            ? shop.secondary_color
            : "#440BD4",
          bgImage: shop.bg_image ? shop.bg_image : null,
          bgImageOpacity: shop.bg_image_opacity ? shop.bg_image_opacity : 1,
          discordLink: shop.discord_link ? shop.discord_link : "https://discord.gg/whaletogether",
          twitterLink: shop.twitter_link ? shop.twitter_link : "https://twitter.com/WhaleTogether",
          loginBgUrl : shop.login_bg_url ? shop.login_bg_url : shopBG,
          loginLogoUrl : shop.login_logo_url ? shop.login_logo_url : logoBig,
          tokenContractAddress : shop.erc20Address ? shop.erc20Address : "0x507238B65e91089C7c5aB5c79f4E66Ec58Ea360A",
          tokenCurrency : shop.token_currency ? shop.token_currency : "$MOON",
          tokenLogo : shop.token_logo ? shop.token_logo : moonCoin,
          tokenBurnAddress : shop.token_burn_address ? shop.token_burn_address : "0xdEAD000000000000000042069420694206942069",
          tokenSymbol : shop.token_symbol ? shop.token_symbol : "MOON",
          tokenDecimal : shop.token_decimal ? shop.token_decimal : 18
        });
      }else{
        localStorage.setItem("initailized",false);
      }
    });
  }
  setInitailized(initailized){
    this.setState({initailized});
  }
  setPrimaryColor(color){
    this.setState({primaryColor:color});
  }
  setSecondaryColor(color){
    this.setState({secondaryColor:color});
  }
  setTitle(title){
    this.setState({title});
  }
  setCompany(company){
    this.setState({company});
  }
  setLogoUrl(logoUrl){
    this.setState({logoUrl});
  }
  setCoverUrl(coverUrl){
    this.setState({coverUrl});
  }
  setDiscordLink(discordLink){
    this.setState({discordLink});
  }
  setTwitterLink(twitterLink){
    this.setState({twitterLink});
  }
  setLoginLogoUrl(loginLogoUrl){
    this.setState({loginLogoUrl});
  }
  setLoginBgUrl(loginBgUrl){
    this.setState({loginBgUrl});
  }
  setTokenContractAddress(tokenContractAddress){
    this.setState({tokenContractAddress});
  }
  setTokenCurrency(tokenCurrency){
    this.setState({tokenCurrency});
  }
  setTokenLogo(tokenLogo){
    this.setState({tokenLogo});
  }
  setTokenBurnAddress(tokenBurnAddress){
    this.setState({tokenBurnAddress});
  }
  setTokenSymbol(tokenSymbol){
    this.setState({tokenSymbol});
  }
  setTokenDecimal(tokenDecimal){
    this.setState({tokenDecimal});
  }
  setErc20Address(erc20Address){
    this.setState({erc20Address});
  }
  setDevWallet(devWallet){
    this.setState({devWallet});
  }
  setRaffleRole(raffleRole){
    this.setState({raffleRole});
  }
  setConfigChannel(configChannel){
    this.setState({configChannel});
  }
  setShowCompany(showCompany){
    this.setState({showCompany});
  }
  render() {
    return (
      <ShopContext.Provider
        value={{
          initailized : this.state.initailized,
          erc20Address : this.state.erc20Address,
          setErc20Address : this.setErc20Address,
          devWallet : this.state.devWallet,
          setDevWallet : this.setDevWallet,
          raffleRole : this.state.raffleRole,
          setRaffleRole : this.setRaffleRole,
          configChannel : this.state.configChannel,
          setConfigChannel : this.setConfigChannel,
          setInitailized : this.setInitailized,
          updateShop : this.updateShop,
          textColor: this.state.textColor,
          title: this.state.title,
          setTitle : this.setTitle,
          company : this.state.company,
          setCompany :this.setCompany,
          showCompany : this.state.showCompany,
          setShowCompany : this.setShowCompany,
          logoUrl : this.state.logoUrl,
          setLogoUrl : this.setLogoUrl,
          coverUrl : this.state.coverUrl,
          setCoverUrl :this.setCoverUrl,
          primaryColor : this.state.primaryColor,
          setPrimaryColor : this.setPrimaryColor,
          secondaryColor : this.state.secondaryColor,
          setSecondaryColor : this.setSecondaryColor,
          bgImage : this.state.bgImage,
          bgImageOpacity : this.state.bgImageOpacity,
          discordLink : this.state.discordLink,
          setDiscordLink : this.setDiscordLink,
          twitterLink : this.state.twitterLink,
          setTwitterLink : this.setTwitterLink ,
          loginBgUrl : this.state.loginBgUrl,
          setLoginBgUrl : this.setLoginBgUrl,
          loginLogoUrl : this.state.loginLogoUrl,
          setLoginLogoUrl : this.setLoginLogoUrl,
          tokenContractAddress : this.state.tokenContractAddress,
          setTokenContractAddress :this.setTokenContractAddress,
          tokenCurrency : this.state.tokenCurrency,
          setTokenCurrency : this.setTokenCurrency,
          tokenLogo : this.state.tokenLogo,
          setTokenLogo : this.setTokenLogo,
          tokenBurnAddress : this.state.tokenBurnAddress,
          setTokenBurnAddress : this.setTokenBurnAddress,
          tokenSymbol : this.state.tokenSymbol,
          setTokenSymbol : this.setTokenSymbol,
          tokenDecimal : this.state.tokenDecimal,
          setTokenDecimal : this.setTokenDecimal, 
        }}
      >
        {this.props.children}
      </ShopContext.Provider>
    );
  }
}
export default ShopProvider;
