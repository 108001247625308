import React from "react";
import { Card, CardContent, Grid, Skeleton, Link } from "@mui/material";

import AlphieImageComponent from "./AlphieImageComponent";
import BuyAlphieNoteComponent from "../BuyAlphieNoteComponent";

function AlphieListComponent({
  isAlphieListLoading,
  alphies,
  totalAlphiesHold,
}) {
  return (
    <Card>
      <CardContent style={{ padding: "2.8em 2.2em" }}>
        <h2 className="text-left mb-4">
          Alphie{alphies.length === 0 ? "" : "s"} ({alphies.length})
        </h2>
        <Grid container spacing={2}>
          {!isAlphieListLoading ? (
            alphies.length > 0 ? (
              alphies.map((token) => (
                <Grid item md={3} sm={6} xs={12} key={token}>
                  <Link
                    href={`https://alphie.whaletogether.com/alphies/${token.id}`}
                    rel="noreferrer"
                    className="text-decoration-none"
                    target="_blank"
                  >
                    <Card elevation={2}>
                      <AlphieImageComponent
                        src={token.image}
                        alt={token.name}
                      />
                      <CardContent>
                        <h4 className="text-center">{token.name}</h4>
                      </CardContent>
                    </Card>
                  </Link>
                </Grid>
              ))
            ) : (
              <BuyAlphieNoteComponent />
            )
          ) : (
            [1, 2, 3, 4].map((i) => (
              <Grid item md={3} sm={6} xs={12} key={i}>
                <Card elevation={2}>
                  <Skeleton variant="rectangular" height={200} />
                  <CardContent>
                    <h4>
                      <Skeleton variant="text" />
                    </h4>
                    <h4>
                      <Skeleton variant="text" />
                    </h4>
                  </CardContent>
                </Card>
              </Grid>
            ))
          )}
        </Grid>
      </CardContent>
    </Card>
  );
}

export default AlphieListComponent;
