import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime';
import { useMediaQuery } from 'react-responsive';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import SectionHeader from "../SectionHeader";
import CampaignListComponent from '../CampaignListComponent';

import { getMyWinsCall } from '../../api';
import useActiveWeb3React from '../../hooks/useActiveWeb3React';
import useAuth from "../../hooks/useAuth";
import { Card, CardContent, Stack } from "@mui/material";
import SadAlphie from "../Shared/SadAlphie";
import ShopContext from "../../providers/ShopContext";

function MyWinsComponent() {
  const shopcontext = React.useContext(ShopContext);
  const { primaryColor, secondaryColor } = shopcontext;
  dayjs.extend(relativeTime);

  const { active, account } = useActiveWeb3React();
  const [isLoading, setIsLoading] = useState(true);
  const [winCampaigns, setWinCampaigns] = useState([]);
  const [totalWins, setTotalWins] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const { logout } = useAuth();

  useEffect(() => {
    if (active && account) {
      init();
    }
  }, [active, account]);

  const init = async () => {
    try {
      await getMyWins();
      setIsLoading(false);
    } catch (e) {
      logout(account);
    }
  }

  const getMyWins = async (page = 1, limit = 10) => {
    try {
      const { campaigns } = await getMyWinsCall(account, page, limit);
      setTotalWins(campaigns.totalDocs);
      setTotalPages(campaigns.totalPages);
      setWinCampaigns(campaigns.docs);
    } catch (error) {
      console.log('error:', error);
    }
  }

  const onChangePage = async (e, value) => {
    const page = value;
    await getMyWins(page);
  }

  return (
    <>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {!isLoading && winCampaigns && (
        <Container className="text-center">
          <SectionHeader
            className="header-font above-fold-header"
            title="My Wins"
            size={1}
          />
          <Card>
            <CardContent>
              {totalWins > 0 ? (
                <>
                  <Row className="mb-4">
                    <Col>
                      <div
                        style={{
                          paddingBottom: '0px !important',
                          background: `linear-gradient(63deg, ${primaryColor} 0%, ${secondaryColor} 100%)`,
                          width: '100%',
                          color: 'white',
                          padding: '15px',
                          borderRadius: '8px',
                        }}
                      >
                        <h5
                          style={{
                            marginBottom: '0px',
                          }}
                        >Neat! You've won <b>{totalWins}</b> so far! 🎉</h5>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <CampaignListComponent
                        campaigns={winCampaigns}
                        totalPages={totalPages}
                        onChangePage={onChangePage}
                      />
                    </Col>
                  </Row>
                </>
              ) : (
                <Stack className="py-4">
                  <div><SadAlphie size={250}/></div>
                  <h5>Sorry, you haven't won anything yet...</h5>
                </Stack>
              )}
            </CardContent>
          </Card>
        </Container>
      )}
    </>
  );
}

export default MyWinsComponent;