import React from "react";
import { useMediaQuery } from 'react-responsive';

import "./BackgroundImage.scss";

function BackgroundImage(props) {
  const isMobile = useMediaQuery({ maxWidth: 991 });
  return (
    <div
      className={"BackgroundImage" + (props.repeat ? " repeat" : "")}
      style={{
        "--image": `url(${props.image})`,
        "--opacity": props.opacity,
        backgroundSize: isMobile ? 'cover' : 'contain',
        backgroundColor: 'black',
        backgroundRepeat: 'repeat-x',
        width: '100%',
      }}
    />
  );
}

export default BackgroundImage;
