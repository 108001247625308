import React, { useEffect, useState } from "react";
import { Col, Button } from "react-bootstrap";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import { getAccount } from '../../api';
import useActiveWeb3React from '../../hooks/useActiveWeb3React';

import { Link } from '../../util/router';
import useAuth from "../../hooks/useAuth";

import "./AdminComponent.scss";

function AdminComponent(props) {
  const { library, active, account } = useActiveWeb3React();
  const [moonAccount, setMoonAccount] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const { logout } = useAuth();

  // const { addToast } = useToasts();

  useEffect(() => {
    if (account) {
      init();
    }
  }, [account]);

  const init = async () => {
    try {
      await getMoonAccount();
      setIsLoading(false);
    } catch (e) {
      logout(account);
    }
  }

  const getMoonAccount = async () => {
    try {
      const moonAccount = await getAccount(account);
      setMoonAccount(moonAccount);
    } catch (error) {
      console.log('error:', error);
    }
  }

  return (
    <>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {!isLoading && moonAccount && moonAccount.account.type === 'admin' ? (
        <>
        <Col xs={12} className="text-center padding">
          <Link to="/campaigns/new">
            <Button size="lg">
              Create New Campaign
            </Button>
          </Link>
        </Col>
        <Col xs={12} className="text-center padding">
          <Link to="/custom-theme">
            <Button size="lg">
              Update Shop Theme
            </Button>
          </Link>
        </Col>
        </>
      ) : (
        <Col xs={12} className="text-center">
          <h1>You're not allowed to be here</h1>
        </Col>
      )}

    </>
  );
}

export default AdminComponent;