import React from "react";

import MyWinsSection from "../components/MyWinsSection";

function MyWinsPage(props) {
  return (
    <MyWinsSection
      textColor="white"
      variant="dark"
      title="My Wins"
      subtitle=""
    />
  );
}

export default MyWinsPage;
