import React from "react";
import CustomThemeSection from "../components/CustomThemeSection";
import ShopContext from "../providers/ShopContext";

function CustomThemePage(props) {
  const shopcontext = React.useContext(ShopContext);
  const {textColor} = shopcontext;
  return (
    <CustomThemeSection
      textColor={textColor}
      variant="dark"
      title="New Campaign"
      subtitle=""
    />
  );
}

export default CustomThemePage;
