import React, { useEffect, useState } from "react";
import Web3Token from "web3-token";

import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { useHistory, useLocation } from "../util/router";
import { getAccount, register } from "../api";
import useActiveWeb3React from "../hooks/useActiveWeb3React";
import useAuth from "../hooks/useAuth";
import { injectedConnector } from "../util/connectors";
import {
  CONNECTOR_LOCAL_STORAGE_KEY,
  WEB3_TOKEN_KEY,
  DISCORD_LOGIN_URL,
} from "../config/constant";
import { MoonAccountContext } from "./MoonAccountProvider";
import { getThemeShop } from "../api/shop";
import _ from "lodash";

function MetamaskProvider({ children }) {
  const {
    library,
    account,
    active: networkActive,
    error: networkError,
    activate: activateNetwork,
  } = useActiveWeb3React();
  const { login, logout } = useAuth();
  const [moonAccount, setMoonAccount] = useState();
  const location = useLocation();
  const history = useHistory();
  const [loaded, setLoaded] = useState(location.pathname);
  const [initailized, setInitailized] = useState(false);

  useEffect(() => {
    setInitailized(
      localStorage.getItem("initailized")
        ? JSON.parse(localStorage.getItem("initailized"))
        : false,
    );
    /*getThemeShop().then((res) => {
      console.log(res);
      if (!_.isNull(res.shop)) {
        setInitailized(true);
      }
      setServerChecked(true);
    });*/
  }, []);

  useEffect(() => {
    const connectorLocalStorageKey = localStorage.getItem(
      CONNECTOR_LOCAL_STORAGE_KEY,
    );
    injectedConnector
      .isAuthorized()
      .then(async (isAuthorized) => {
        if (location.pathname.startsWith("/error/")) {
          await login(connectorLocalStorageKey || "injected");
          setLoaded(true);
          return;
        }

        if (
          isAuthorized &&
          !networkActive &&
          !networkError &&
          connectorLocalStorageKey
        ) {
          await login(connectorLocalStorageKey);
        }

        const redirectQuery =
          location.pathname !== "/login" ? `?from=${location.pathname}` : "";

        if (isAuthorized && networkActive) {
          const web3Token = localStorage.getItem(
            `${WEB3_TOKEN_KEY}-${account}`,
          );
          if (!web3Token) {
            const signer = library.getSigner();

            let authToken;
            try {
              authToken = await Web3Token.sign(
                async (msg) => {
                  try {
                    return await signer.signMessage(msg);
                  } catch (err) {
                    const { reason } = err;
                    if (reason === "unknown account #0") {
                      return console.log(
                        "Have you unlocked metamask and are connected to this page?",
                      );
                    }
                    logout(account);
                    return reason;
                  }
                },
                {
                  expires_in: "14 days",
                },
              );
            } catch (err) {
              if (/returns a signature/.test(err.toString())) {
                return;
              }
              return;
            }

            localStorage.setItem(`${WEB3_TOKEN_KEY}-${account}`, authToken);
            try {
              const moonAccount = await getAccount(account);
              setMoonAccount(moonAccount);
            } catch (error) {
              await register(authToken);
              const moonAccount = await getAccount(account);
              setMoonAccount(moonAccount);

              setTimeout(() => {
                window.location.href = DISCORD_LOGIN_URL;
              }, 100);
            }
          } else {
            const moonAccount = await getAccount(account);
            await getAccount(account);
            setMoonAccount(moonAccount);
          }
        }

        if (!networkActive) {
          setLoaded(true);
          history.push(`/login${redirectQuery}`);
          return;
        }

        const redirect = new URLSearchParams(location.search).get("from");
        setLoaded(true);
        /*if (initailized) {
          history.push(redirect || '/');
        } else {
          history.push('/custom-theme');
        }*/
        history.push(redirect || "/");
      })
      .catch(async (err) => {
        logout(account);
        return;
      });
  }, [activateNetwork, login, logout, account, networkActive, networkError]);

  return loaded ? (
    <MoonAccountContext.Provider value={moonAccount}>
      {children}
    </MoonAccountContext.Provider>
  ) : (
    <Backdrop
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={!loaded}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  );
}

export default MetamaskProvider;
