import React, { useCallback, useContext, useEffect, useState } from "react";
import { Container, Button, Tabs, Tab } from "react-bootstrap";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";

import CampaignHeader from "../CampaignHeader";
import EntryListComponent from "./EntryListComponent";
import BuyAlphieNoteComponent from "../BuyAlphieNoteComponent";
import JoinNowModal from "../JoinNowModal";

import { getCampaign } from "../../api";
import useActiveWeb3React from "../../hooks/useActiveWeb3React";
import { useNFT } from "../../hooks/useContract";
import { useParams } from "../../util/router";
import useAuth from "../../hooks/useAuth";

import { dropTypesLabel, dropTypes } from "../../enums/dropTypes";
import {
  calculateDutchMetaData,
  calculateRaffleMetaData,
  getEntriesByMoonAccount,
  getLiveStatus,
  getProbablityOfWinning,
  getStartsIn,
  getTotalEntries,
  statusText,
} from "../../models/campaign.js";
import {
  Card,
  CardContent,
  CardMedia,
  Grid,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import CircularProgressWithLabel from "../Shared/CircularProgressWithLabel";
import MoonIcon from "../Shared/MoonIcon";
import { campaignStatuses } from "../../enums/campaignStatuses";
import { MoonAccountContext } from "../../providers/MoonAccountProvider.js";
import { useTheme } from "@mui/system";
import { DateTime } from "luxon";
import ShopContext from "../../providers/ShopContext";

const NFT_CONTRACT_ADDRESS = process.env.REACT_APP_NFT_CONTRACT_ADDRESS;
dayjs.extend(duration);

function CampaignComponent() {
  const shopcontext = React.useContext(ShopContext);
  const { primaryColor, secondaryColor, tokenSymbol } = shopcontext;

  const theme = useTheme();
  const { active, account } = useActiveWeb3React();
  const [isLoading, setIsLoading] = useState(true);
  const [campaign, setCampaign] = useState();
  const [nftContract] = useState(useNFT(NFT_CONTRACT_ADDRESS));
  const [totalAlphiesHold, setTotalAlphiesHold] = useState(0);
  const [dutchMeta, setDutchMeta] = useState(0);
  const [raffleMeta, setRaffleMeta] = useState(0);
  const [totalEntries, setTotalEntries] = useState(0);
  const [userEntries, setUserEntries] = useState(0);
  const [winningPercentage, setWinningPercentage] = useState(0);
  const [campaignStatus, setCampaignStatus] = useState(
    campaignStatuses.scheduled
  );
  const moonAccount = useContext(MoonAccountContext);
  const { id } = useParams();
  const { logout } = useAuth();
  const [startsIn, setStartsIn] = useState(false);

  const getMoonCampaign = useCallback(async () => {
    try {
      const { campaign } = await getCampaign(account, id);
      setCampaign(campaign);
    } catch (error) {
      console.log("error:", error);
    }
  }, [account, id]);

  useEffect(() => {
    const init = async () => {
      try {
        await getMoonCampaign();
        setIsLoading(false);
      } catch (e) {
        logout(account);
      }
    };

    const getAlphiesByAccount = async () => {
      const tokenIds = await nftContract.walletOfOwner(account);
      setTotalAlphiesHold(tokenIds.length);
    };

    if (nftContract && active && account) {
      init();
      getAlphiesByAccount();
    }
  }, [id, active, account, nftContract, logout, getMoonCampaign]);

  useEffect(() => {
    let intervalTimer;
    let startTimer;
    if (campaign) {
      setTotalEntries(getTotalEntries(campaign));
      setUserEntries(getEntriesByMoonAccount(campaign, moonAccount));
      if (campaign.dropType === dropTypes.raffle) {
        const percentage = getProbablityOfWinning(campaign, moonAccount) * 100;
        setWinningPercentage(
          percentage >= 100
            ? percentage.toFixed(0)
            : percentage >= 10
            ? percentage.toFixed(1)
            : percentage.toFixed(2)
        );
      }
      switch (campaign.dropType) {
        case dropTypes.dutch:
          setCampaignStatus(getLiveStatus(campaign));
          intervalTimer = setInterval(() => {
            setDutchMeta(calculateDutchMetaData(campaign));
            const liveStatus = getLiveStatus(campaign);
            setCampaignStatus(liveStatus);
            if (liveStatus === campaignStatuses.completed) {
              if (intervalTimer) clearInterval(intervalTimer);
            }
          }, 1000);
          break;
        case dropTypes.raffle:
          setCampaignStatus(getLiveStatus(campaign));
          intervalTimer = setInterval(() => {
            setRaffleMeta(calculateRaffleMetaData(campaign));
            const liveStatus = getLiveStatus(campaign);
            setCampaignStatus(liveStatus);
            if (liveStatus === campaignStatuses.completed) {
              if (intervalTimer) clearInterval(intervalTimer);
            }
          }, 1000);
          break;
        default:
          setCampaignStatus(getLiveStatus(campaign));
      }
      if (getLiveStatus(campaign) === campaignStatuses.scheduled) {
        startTimer = setInterval(() => {
          setStartsIn(getStartsIn(campaign));
          const liveStatus = getLiveStatus(campaign);
          setCampaignStatus(liveStatus);
          if (liveStatus !== campaignStatuses.scheduled) {
            getMoonCampaign();
            if (startTimer) clearInterval(startTimer);
          }
        }, 1000);
      }
      return () => {
        if (intervalTimer) clearInterval(intervalTimer);
      };
    }
  }, [campaign, moonAccount]);

  const [modalState, setModalState] = useState(false);

  const toggleModal = () => {
    setModalState(!modalState);
  };

  const onModalClose = async () => {
    toggleModal();
    await getMoonCampaign();
  };

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {!isLoading && campaign && (
        <>
          <JoinNowModal
            open={modalState}
            onClose={onModalClose}
            campaign={campaign}
            account={account}
            currentPrice={
              campaign.dropType === dropTypes.dutch
                ? dutchMeta.currentPrice
                : null
            }
            remainingText={
              campaign.dropType === dropTypes.dutch
                ? dutchMeta.intervalRemaining
                : null
            }
          />
          <Container className="text-center">
            <CampaignHeader
              className="header-font above-fold-header"
              title={campaign.projectName}
              subtitle={campaign.description}
              websiteUrl={campaign.websiteUrl}
              twitterUrl={campaign.twitterUrl}
              discordUrl={campaign.discordUrl}
              mintPrice={campaign.mintPrice}
              mintCurrency={campaign.mintCurrency}
              chain={campaign.chain}
              totalSupply={campaign.totalSupply}
              size={1}
            />

            <Stack spacing={4}>
              {campaign.imageUrl && (
                <Card>
                  <CardMedia
                    component="img"
                    height="250"
                    image={campaign.imageUrl}
                  />
                </Card>
              )}
              <Card>
                <CardContent style={{ padding: "2.8em 2.5em" }}>
                  <Grid container spacing={3}>
                    <Grid item md={4} sm={6} xs={12}>
                      <h6 className="mb-3">TYPE</h6>
                      <h3>{dropTypesLabel[campaign.dropType]}</h3>
                    </Grid>
                    <Grid item md={4} sm={6} xs={12}>
                      <h6 className="mb-3">STATUS</h6>
                      <h3>{statusText[campaignStatus] ?? "N/A"}</h3>
                    </Grid>
                    <Grid item md={4} sm={6} xs={12}>
                      <h6 className="mb-3">Winners</h6>
                      <h3>{campaign.wlAvailable}</h3>
                    </Grid>
                    <Grid item md={4} sm={6} xs={12}>
                      <h6 className="mb-3">PRICE PER ENTRY</h6>
                      <h3>
                        {(() => {
                          switch (campaign.dropType) {
                            case dropTypes.dutch:
                              return (
                                <>
                                  {campaignStatus === campaignStatuses.active
                                    ? dutchMeta.currentPrice
                                    : "—"}
                                  <MoonIcon size={20} />
                                </>
                              );
                            default:
                              return (
                                <>
                                  {campaign.pricePerEntry !== 0
                                    ? campaign.pricePerEntry
                                    : "FREE"}
                                  {campaign.pricePerEntry !== 0 ? (
                                    <MoonIcon size={30} />
                                  ) : null}
                                </>
                              );
                          }
                        })()}
                      </h3>
                      {campaign.dropType === dropTypes.dutch && (
                        <Typography variant="caption" component="div">
                          Price decreased by
                          <br />
                          {campaign.decreasePerInterval} ${tokenSymbol} per{" "}
                          {Math.round(campaign.decreaseInterval / 60000)}{" "}
                          minutes
                        </Typography>
                      )}
                    </Grid>
                    <Grid item md={4} sm={6} xs={12}>
                      <h6 className="mb-3">ENTRIES</h6>
                      <h4>
                        <b>{userEntries}</b> / {totalEntries}
                      </h4>
                      <Typography component="div" variant="caption">
                        <b>yours</b> / total
                      </Typography>
                      {campaign.dropType === dropTypes.raffle && (
                        <Typography component="div" variant="caption">
                          winning chance: {winningPercentage}%
                        </Typography>
                      )}
                    </Grid>
                    <Grid item md={4} sm={6} xs={12}>
                      {campaignStatus === campaignStatuses.active ? (
                        (() => {
                          switch (campaign.dropType) {
                            case dropTypes.dutch:
                              return (
                                dutchMeta.currentPrice >
                                  campaign.endingPrice && (
                                  <Stack
                                    alignItems="center"
                                    justifyContent="center"
                                    spacing={0}
                                  >
                                    <h6 className="text-uppercase">
                                      Price Update In
                                    </h6>
                                    <CircularProgressWithLabel
                                      size={57}
                                      thickness={5}
                                      textvariant="largeCounterLabel"
                                      variant="determinate"
                                      value={
                                        dutchMeta.intervalRemainingPercentage ??
                                        0
                                      }
                                      label={dutchMeta.intervalRemaining ?? ""}
                                    />
                                    <Typography
                                      component="div"
                                      style={{ marginTop: "5px" }}
                                    >
                                      {dayjs
                                        .duration(
                                          dutchMeta.intervalRemainingSeconds +
                                            1,
                                          "seconds"
                                        )
                                        .format("HH:mm:ss")}
                                    </Typography>
                                  </Stack>
                                )
                              );
                            case dropTypes.raffle:
                              return (
                                <Tooltip
                                  title={DateTime.fromISO(campaign.startTime)
                                    .plus(campaign.durationMs)
                                    .toLocaleString(DateTime.DATETIME_SHORT)}
                                  arrow
                                >
                                  <Stack
                                    alignItems="center"
                                    justifyContent="center"
                                    spacing={0}
                                  >
                                    <h6 className="text-uppercase">
                                      Ending In
                                    </h6>
                                    <CircularProgressWithLabel
                                      size={57}
                                      thickness={5}
                                      textvariant="largeCounterLabel"
                                      variant="determinate"
                                      value={
                                        raffleMeta.remainingPercentage ?? 0
                                      }
                                      label={raffleMeta.remaining ?? ""}
                                    />
                                    <Typography
                                      component="div"
                                      style={{ marginTop: "5px" }}
                                    >
                                      {dayjs
                                        .duration(
                                          raffleMeta.remainingSeconds,
                                          "seconds"
                                        )
                                        .format("HH:mm:ss")}
                                    </Typography>
                                  </Stack>
                                </Tooltip>
                              );
                            default:
                              return null;
                          }
                        })()
                      ) : campaignStatus === campaignStatuses.scheduled ? (
                        <Tooltip
                          title={DateTime.fromISO(
                            campaign.startTime
                          ).toLocaleString(DateTime.DATETIME_SHORT)}
                          arrow
                        >
                          <Stack
                            alignItems="center"
                            justifyContent="center"
                            spacing={0}
                          >
                            <h6 className="text-uppercase">Starts In</h6>
                            <CircularProgressWithLabel
                              color="secondary"
                              size={57}
                              thickness={5}
                              variant="determinate"
                              value={startsIn.percentage ?? 100}
                              label={startsIn.label ?? ""}
                              textvariant="largeCounterLabel"
                            />
                          </Stack>
                        </Tooltip>
                      ) : null}
                    </Grid>
                  </Grid>
                  <Grid container justifyContent="center">
                    {campaignStatus === campaignStatuses.active && (
                      <Button
                        size="lg"
                        style={{
                          background: `linear-gradient(63deg, ${primaryColor} 0%, ${secondaryColor} 100%)`,
                          border: "transparent",
                          fontWeight: "500",
                          marginTop: "25px",
                        }}
                        onClick={toggleModal}
                      >
                        Join Now
                      </Button>
                    )}
                  </Grid>
                </CardContent>
                {campaign.footer && (
                  <CardContent
                    className="text-left"
                    style={{
                      background: theme.palette.background.shade,
                      paddingTop: "1.2em",
                    }}
                  >
                    <Typography variant="caption">{campaign.footer}</Typography>
                  </CardContent>
                )}
              </Card>
              {campaign.instructions ? (
                <Card>
                  <CardContent style={{ padding: "2.8em 2.5em" }}>
                    <Typography variant="h4" component="h4">
                      Instructions
                    </Typography>
                    <Typography mt={2}>{campaign.instructions}</Typography>
                  </CardContent>
                </Card>
              ) : null}
              {totalAlphiesHold <= 0 && <BuyAlphieNoteComponent />}
              <Card>
                <CardContent style={{ padding: "2.8em 2.5em" }}>
                  <Tabs defaultActiveKey="entries" className="mb-3">
                    <Tab
                      eventKey="entries"
                      title={<h5>{`ENTRIES (${campaign.entries.length})`}</h5>}
                    >
                      {campaign.entries.length > 0 ? (
                        <EntryListComponent entries={campaign.entries} />
                      ) : (
                        <>No Entry 😢</>
                      )}
                    </Tab>
                    <Tab
                      eventKey="winners"
                      title={<h5>WINNERS</h5>}
                      disabled={campaignStatus !== campaignStatuses.completed}
                    >
                      {campaign.winners.length > 0 ? (
                        <EntryListComponent
                          entries={campaign.entries}
                          winners={campaign.winners}
                        />
                      ) : (
                        <>No Winner 😢</>
                      )}
                    </Tab>
                  </Tabs>
                </CardContent>
              </Card>
            </Stack>
          </Container>
        </>
      )}
    </>
  );
}

export default CampaignComponent;
