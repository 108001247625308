import sadAlphieImage from "../../images/sad-alphie.png";

function SadAlphie({ size, style, className }) {
    return (
        <img
            className={className}
            src={sadAlphieImage}
            alt="Sad Alphie"
            style={{...style, height: size}}
        />
    );
}

export default SadAlphie;