import { ALPHIEMOON_BE_BASE_URL, WEB3_TOKEN_KEY } from '../config/constant';

export const getTransactions = async (address, page = 1, limit = 10) => {
    if (!address) return false;

    const web3Token = localStorage.getItem(`${WEB3_TOKEN_KEY}-${address}`);

    const url = `${ALPHIEMOON_BE_BASE_URL}/api/moon-transactions/?page=${page}&limit=${limit}`;

    const requestOptions = {
        method: 'GET',
        headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${web3Token}`
        },
    };
    const res = await fetch(url, requestOptions);
    if (res.ok) return res.json();
    throw Error(res.statusText);
}