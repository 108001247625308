import React, { useEffect, useState } from "react";
import useActiveWeb3React from "../hooks/useActiveWeb3React";
import { getTransactions } from "../api/transaction";

import { Stack } from "@mui/material";
import { Container } from "react-bootstrap";
import Section from "../components/Section";
import SectionHeader from "../components/SectionHeader";
import LoadingBackdrop from "../components/Shared/LoadingBackdrop";
import ConnectWalletButton from "../components/Shared/ConnectWalletButton";
import TransactionsList from "../components/TransactionsList";
import useAuth from "../hooks/useAuth";
import ShopContext from "../providers/ShopContext";

function TransactionsPage() {
  const shopcontext = React.useContext(ShopContext);
  const { primaryColor, secondaryColor } = shopcontext;
  const { active, account, error } = useActiveWeb3React();
  const { logout } = useAuth();
  const [ loading, setLoading ] = useState(true);
  const [ transactions, setTransactions ] = useState([]);
  const [ totalTransactions, setTotalTransactions ] = useState(0);
  const [ transactionsPerPage, setTransactionsPerPage ] = useState(10);
  const [ totalPages, setTotalPages] = useState(0);
  const [ page, setPage] = useState(0);
  
  useEffect(() => {
    const fetchTransactions = async (page = 0, limit = 10) => {
      try {
        const { moonTransactions } = await getTransactions(account, page + 1, limit);
        setTransactions(moonTransactions.docs);
        setTotalTransactions(moonTransactions.totalDocs);
        setTotalPages(moonTransactions.totalPages);
      } catch (error) {
        alert(error)
        console.log(error)
      } finally {
        setLoading(false);
      }
    }

    if (active && account) {
      fetchTransactions(page, transactionsPerPage);
    } else {
      //logout(account);
    }
  }, [active, account, page, transactionsPerPage, logout]);

  return (
    <Section
      textColor="white"
      style={{
        paddingBottom: '0px !important',
        background: `linear-gradient(63deg, ${primaryColor} 0%, ${secondaryColor} 100%)`,
      }}
      >
      <Container style={{ padding: '100px 35px', minHeight: '60vh' }}>
        <SectionHeader
          className="text-center header-font above-fold-header"
          title="Transactions"
          size={1}
        />
        <Stack spacing={4}>
          {active && account ?
            <>
              <LoadingBackdrop open={loading} />
              { !loading && 
              <TransactionsList 
                transactions={transactions} 
                page={page} 
                totalPages={totalPages} 
                totalTransactions={totalTransactions} 
                transactionsPerPage={transactionsPerPage}
                gotoPage={setPage} 
                changeTransactionsPerPage={setTransactionsPerPage}
              />}
            </>
          : 
            <ConnectWalletButton error={error} />
          }
        </Stack>
      </Container>
    </Section>
  );
}


export default TransactionsPage;
