import React, { useCallback, useEffect, useState } from "react";
import { formatUnits, formatEther, parseUnits } from "@ethersproject/units";
import {
  Container,
  Form,
  Row,
  Col,
  Button,
  InputGroup,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { useToasts } from "react-toast-notifications";
import Spinner from "react-bootstrap/Spinner";
import { useMediaQuery } from "react-responsive";
import { BsFillInfoCircleFill } from "react-icons/bs";

import useActiveWeb3React from "../../hooks/useActiveWeb3React";
import { useMoonToken } from "../../hooks/useContract";
import { shorter } from "../../util/util";

import AddToMetaMaskButton from "../AddToMetaMaskButton";
import { Card, CardContent } from "@mui/material";
import MoonIcon from "../Shared/MoonIcon";
import ShopContext from "../../providers/ShopContext";

const MOON_EARN_PER_DAY = 5;

function BalanceComponent({ moonAccount, getMoonAccount, totalAlphiesHold }) {
  const shopcontext = React.useContext(ShopContext);
  const { tokenSymbol, tokenContractAddress, tokenBurnAddress } = shopcontext;

  const { library, active, account } = useActiveWeb3React();
  const [moonTokenContract] = useState(useMoonToken(tokenContractAddress));
  const [totalClaimable, setTotalClaimable] = useState(0);
  const [isClaiming, setIsClaiming] = useState(false);
  const [isDepositing, setIsDepositing] = useState(false);
  const [maxWalletBalance, setMaxWalletBalance] = useState(0);

  const [moonToDeposit, setMoonToDeposit] = useState(0);
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const { addToast } = useToasts();

  const getTotalClaimable = useCallback(async () => {
    const totalClaimable = await moonTokenContract.getTotalClaimable(account);
    setTotalClaimable(formatEther(totalClaimable));
  }, [account, moonTokenContract]);

  const getMOONCurrentBalance = useCallback(async () => {
    const moonCurrentBalance = await moonTokenContract.balanceOf(account);
    setMoonToDeposit(formatEther(moonCurrentBalance));
    setMaxWalletBalance(formatEther(moonCurrentBalance));
  }, [account, moonTokenContract]);

  useEffect(() => {
    if (moonTokenContract && active && account) {
      getTotalClaimable();
      getMOONCurrentBalance();
    }
  }, [
    moonTokenContract,
    active,
    account,
    getTotalClaimable,
    getMOONCurrentBalance,
  ]);

  const setMoonToDepositToMax = async () => {
    setMoonToDeposit(maxWalletBalance);
  };

  const claimReward = async () => {
    try {
      setIsClaiming(true);
      const tx = await moonTokenContract.claimReward();
      const receipt = await tx.wait();

      const RewardClaimed = receipt.events?.filter((x) => {
        return x.event === "RewardClaimed";
      });
      const user = RewardClaimed[0].args[0];
      const reward = RewardClaimed[0].args[1];

      getTotalClaimable();
      getMOONCurrentBalance();
      setIsClaiming(false);

      const message = `${shorter(
        user,
      )} has claimed the reward for ${formatUnits(reward, 18)} $${tokenSymbol}`;
      addToast(message, {
        appearance: "info",
        autoDismiss: true,
      });
    } catch (error) {
      setIsClaiming(false);
      addToast(error.reason ? error.reason : error.message, {
        appearance: "error",
        autoDismiss: true,
      });
    }
  };

  const transferMoonToBurn = async () => {
    try {
      if (parseFloat(moonToDeposit) > parseFloat(maxWalletBalance)) {
        throw new Error("You cannot deposit more than what you have, sorry!");
      }

      if (parseFloat(moonToDeposit) <= 0) {
        throw new Error("You cannot deposit that low...");
      }

      setIsDepositing(true);

      const to = tokenBurnAddress;
      const amount = parseUnits(moonToDeposit, 18);

      const gasLimit = await moonTokenContract.estimateGas.transfer(to, amount);
      const gasPrice = await library.getSigner().getGasPrice();

      const tx = await moonTokenContract.transfer(to, amount, {
        gasLimit,
        gasPrice,
      });
      await tx.wait();

      getMOONCurrentBalance();
      getTotalClaimable();

      setTimeout(() => {
        getMoonAccount();
        setIsDepositing(false);
      }, 5000);

      // TODO * Auto add credit to account once the tx is clear.

      const message = `You have transferred ${moonToDeposit} $${tokenSymbol} successfully to your shop balance wallet`;
      addToast(message, {
        appearance: "info",
        autoDismiss: true,
      });
    } catch (error) {
      setIsDepositing(false);
      addToast(error.reason ? error.reason : error.message, {
        appearance: "error",
        autoDismiss: true,
      });
    }
  };

  return (
    <>
      {moonAccount && account && (
        <Card>
          <CardContent style={{ padding: "2.8em 2.2em" }}>
            <Container>
              <Row>
                <Col style={{ marginBottom: "20px" }}>
                  <h2>
                    <MoonIcon size={40} />${tokenSymbol}
                  </h2>
                  <AddToMetaMaskButton />
                </Col>
              </Row>
              <Row>
                <Col md={4} className="mb-4">
                  <h5>
                    Claimable{" "}
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip>
                          Claim all your ${tokenSymbol} tokens to your MM Wallet
                        </Tooltip>
                      }
                    >
                      <BsFillInfoCircleFill
                        style={{ position: "relative", top: "-2px" }}
                      />
                    </OverlayTrigger>
                  </h5>
                  <h5
                    style={{
                      margin: "0 auto",
                      fontSize: "24px",
                      background: "white",
                      color: "black",
                      padding: "10px 12px",
                      borderRadius: "8px",
                      border: "1px solid black",
                    }}
                    className="mb-2"
                  >
                    <span
                      style={{
                        fontWeight: "600",
                      }}
                    >
                      {parseFloat(totalClaimable).toFixed(3)}
                    </span>
                  </h5>
                  <Button
                    onClick={claimReward}
                    disabled={totalClaimable <= 0 || isClaiming || isDepositing}
                    style={{ marginRight: "10px" }}
                    size="sm"
                  >
                    {isClaiming ? (
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                        style={{
                          marginRight: "7px",
                          marginTop: "-5px",
                          position: "relative",
                          top: "-2px",
                        }}
                      />
                    ) : null}
                    {isClaiming ? "Claiming..." : "Claim All"}
                  </Button>
                </Col>
                <Col md={4} className="mb-4">
                  <h5>
                    Wallet Balance{" "}
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip>
                          ${tokenSymbol} balance in your wallet. You can deposit
                          your ${tokenSymbol} tokens to your shop balance wallet
                          by click the deposit button below.
                        </Tooltip>
                      }
                    >
                      <BsFillInfoCircleFill
                        style={{ position: "relative", top: "-2px" }}
                      />
                    </OverlayTrigger>
                  </h5>
                  <div>
                    <Form.Group className="mb-2" controlId="formBasicEmail">
                      <InputGroup className="mb-3">
                        <Form.Control
                          placeholder={`Enter $${tokenSymbol} Amount`}
                          value={moonToDeposit}
                          onChange={({ target: { value } }) =>
                            setMoonToDeposit(value)
                          }
                          type="number"
                          min={0}
                          style={{
                            fontSize: "24px",
                            fontWeight: "600",
                            borderRadius: "8px 0px 0px 8px",
                            border: "1px solid black",
                          }}
                        />
                        <Button
                          style={{
                            fontWeight: "600",
                            borderRadius: "0px 8px 8px 0px",
                            border: "1px solid black",
                          }}
                          size="sm"
                          onClick={setMoonToDepositToMax}
                        >
                          Max
                        </Button>
                      </InputGroup>
                    </Form.Group>
                    <Button
                      onClick={transferMoonToBurn}
                      disabled={
                        isClaiming ||
                        isDepositing ||
                        moonToDeposit === "" ||
                        parseFloat(moonToDeposit) <= 0 ||
                        parseFloat(moonToDeposit) > parseFloat(maxWalletBalance)
                      }
                      size="sm"
                      style={{
                        marginRight: "7px",
                      }}
                    >
                      {isDepositing ? (
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                          style={{
                            marginRight: "7px",
                            marginTop: "-5px",
                            position: "relative",
                            top: "-2px",
                          }}
                        />
                      ) : null}
                      {isDepositing ? "Processing..." : "Deposit"}
                    </Button>
                  </div>
                </Col>

                <Col md={4} className="mb-4">
                  <h5>
                    Shop Balance{" "}
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip>
                          Amount of ${tokenSymbol} you can currently use inside
                          our shop.
                        </Tooltip>
                      }
                    >
                      <BsFillInfoCircleFill
                        style={{ position: "relative", top: "-2px" }}
                      />
                    </OverlayTrigger>
                  </h5>
                  <h5
                    style={{
                      margin: "0 auto",
                      fontSize: "24px",
                      background: "white",
                      color: "black",
                      padding: "10px 12px",
                      borderRadius: "8px",
                      border: "1px solid black",
                    }}
                  >
                    <span
                      style={{
                        fontWeight: "600",
                      }}
                    >
                      {parseFloat(moonAccount.moon[0].moonBalance).toFixed(3)}
                    </span>
                  </h5>
                </Col>
              </Row>
              <Row className={isMobile ? "mb-3" : ""}>
                <Col>
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip>
                        You are holding <b>{totalAlphiesHold}</b> Alphie
                        {totalAlphiesHold === 1 ? "" : "s"}. Each Alphie is
                        generating{" "}
                        <b>
                          {MOON_EARN_PER_DAY} <MoonIcon size={20} />
                        </b>{" "}
                        per day
                      </Tooltip>
                    }
                  >
                    <h6
                      style={{
                        position: "absolute",
                        right: "15px",
                      }}
                    >
                      *You're earning{" "}
                      <b>
                        {MOON_EARN_PER_DAY * totalAlphiesHold} ${tokenSymbol}{" "}
                        <MoonIcon size={20} />
                      </b>{" "}
                      per day
                    </h6>
                  </OverlayTrigger>
                </Col>
              </Row>
            </Container>
          </CardContent>
        </Card>
      )}
    </>
  );
}

export default BalanceComponent;
