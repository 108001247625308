import React, { useEffect, useState } from "react";
import reactCSS from "reactcss";
import { SketchPicker } from "react-color";
import { hexToRgb } from "@mui/material";
//import hexToRgba from 'hex-to-rgba';
import rgbHex from "rgb-hex";

const hex2rgba = (hex, alpha = 1) => {
  const [r, g, b] = hex.match(/\w\w/g).map((x) => parseInt(x, 16));
  return { r, g, b, a: alpha };
};

export default function ColorPicker(props) {
  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const [color, setColor] = useState({
    r: 241,
    g: 112,
    b: 19,
    a: 1,
  });

  useEffect(() => {
    if (props.color) {
      let rgba = hex2rgba(props.color);
      setColor(rgba);
    }
  }, [props.color]);

  const handleClick = () => {
    setDisplayColorPicker(!displayColorPicker);
  };
  const handleClose = () => {
    setDisplayColorPicker(false);
  };
  const handleChange = (_color) => {
    //console.log(_color);
    setColor(_color.rgb);
    if (props.onChange) {
      props.onChange(_color.hex);
    }
  };

  const styles = reactCSS({
    default: {
      color: {
        width: "36px",
        height: "14px",
        borderRadius: "2px",
        background: `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})`,
      },
      swatch: {
        padding: "5px",
        background: "#fff",
        borderRadius: "1px",
        boxShadow: "0 0 0 1px rgba(0,0,0,.1)",
        display: "inline-block",
        cursor: "pointer",
      },
      popover: {
        position: "absolute",
        zIndex: "2",
        marginTop: "25px",
      },
      cover: {
        position: "fixed",
        top: "0px",
        right: "0px",
        bottom: "0px",
        left: "0px",
      },
    },
  });

  return (
    <div style={{ display: "flex",paddingRight:10 }}>
      <div style={styles.swatch} onClick={handleClick}>
        <div style={styles.color} />
      </div>
      {displayColorPicker ? (
        <div style={styles.popover}>
          <div style={styles.cover} onClick={handleClose} />
          <SketchPicker color={color} onChange={handleChange} />
        </div>
      ) : null}
    </div>
  );
}
