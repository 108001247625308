import { Enum } from "./enums";

export const dropTypes = Enum({
  fcfs: "FCFS",
  FCFS: "FCFS",
  raffle: "raffle",
  dutch: "dutch",
});

export const dropTypesLabel = Enum({
  fcfs: "FCFS",
  FCFS: "FCFS",
  raffle: "Raffle",
  dutch: "Dutch Action",
});

export const chains = Enum({
  eth: "eth",
  btc: "btcOrdinal",
});

export const chainsLabel = Enum({
  eth: "Ethereum",
  btc: "BTC Ordinal",
});

export const dropTypesBgColor = Enum({
  fcfs: "#440BD4",
  FCFS: "#440BD4",
  raffle: "#FF2079",
  dutch: "#000",
});
