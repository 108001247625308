import React, { useEffect, useState } from "react";
import { formatUnits } from "@ethersproject/units";
import Container from "react-bootstrap/Container";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import SectionHeader from "../SectionHeader";
import BalanceComponent from './BalanceComponent';
import SocialAccountComponent from './SocialAccountComponent';
import WLWalletComponent from './WLWalletComponent';
import AlphieListComponent from './AlphieListComponent';

import { getAccount } from '../../api';
import useActiveWeb3React from '../../hooks/useActiveWeb3React';
import { useNFT } from '../../hooks/useContract';

import "./AccountComponent.scss";
import useAuth from "../../hooks/useAuth";
import { Stack } from "@mui/material";
import TwitterAccountComponent from "./TwitterAccountComponent";

const NFT_CONTRACT_ADDRESS = process.env.REACT_APP_NFT_CONTRACT_ADDRESS;

function timeout(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

function AccountComponent() {
  const { active, account } = useActiveWeb3React();
  const [isLoading, setIsLoading] = useState(true);
  const [moonAccount, setMoonAccount] = useState();
  const [nftContract] = useState(useNFT(NFT_CONTRACT_ADDRESS));
  const [alphies, setAlphies] = useState([]);
  const [totalAlphiesHold, setTotalAlphiesHold] = useState(0);
  const [isAlphieListLoading, setIsAlphieListLoading] = useState(true);
  const { logout } = useAuth();


  useEffect(() => {
    if (nftContract && active && account) {
      init();
      getAlphiesByAccount();
    }
  }, [nftContract, active, account]);

  const init = async () => {
    await getMoonAccount();
    setIsLoading(false);
  }

  const getMoonAccount = async () => {
    try {
      const moonAccount = await getAccount(account);
      setMoonAccount(moonAccount);
    } catch (error) {
      logout(account);
      console.log('error:', error);
    }
  }

  const fetchNFTFromTokenURI = async (id) => {
    try {
      const tokenURI = await nftContract.tokenURI(id);
      const response = await fetch(tokenURI, { method: "GET", mode: 'cors', headers: { 'Content-Type': 'application/json',}})
      return response.json();
    } catch (error) {
      console.log('error=====>>>>>', error);
    }
  }

  const getAlphiesByAccount = async () => {
    const tokenIds = await nftContract.walletOfOwner(account);
    setTotalAlphiesHold(tokenIds.length);

    const alphies = [];
    for (let tokenId of tokenIds) {
      const id = formatUnits(tokenId, 0);
      const token = await fetchNFTFromTokenURI(id);
      alphies.push({
        ...token,
        id,
      });
      timeout(300);
    }

    setAlphies(alphies);
    setIsAlphieListLoading(false);
  }

  return (
    <>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {!isLoading && account && (
        <Container>
          <SectionHeader
            className="header-font above-fold-header text-center"
            title="My Account"
            size={1}
          />
          {moonAccount && (
            <Stack spacing={4}>
              <BalanceComponent moonAccount={moonAccount} getMoonAccount={getMoonAccount} totalAlphiesHold={totalAlphiesHold} />
              <SocialAccountComponent moonAccount={moonAccount} getMoonAccount={getMoonAccount} />
              <TwitterAccountComponent  moonAccount={moonAccount} getMoonAccount={getMoonAccount} />
              <WLWalletComponent moonAccount={moonAccount} />
              <AlphieListComponent isAlphieListLoading={isAlphieListLoading} alphies={alphies} totalAlphiesHold={totalAlphiesHold} />
            </Stack>
          )}
        </Container>
      )}
    </>
  );
}

export default AccountComponent;