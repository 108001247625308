import React from 'react';
import { Button } from 'react-bootstrap';
import { connectorsConfig } from '../../config/constant';
import ShopContext from '../../providers/ShopContext';

function AddToMetaMaskButton(props) {
  const shopcontext = React.useContext(ShopContext);
  const {
    tokenCurrency,
    tokenLogo,
    tokenContractAddress,
    tokenSymbol,
    tokenDecimal,
  } = shopcontext;

  const { icon: Icon } = connectorsConfig[0];

  const addMoonToMetaMask = async () => {
    try {
      await window.ethereum.request({
        method: 'wallet_watchAsset',
        params: {
          type: 'ERC20', // Initially only supports ERC20, but eventually more!
          options: {
            address: tokenContractAddress, // The address that the token is at.
            symbol: tokenSymbol, // A ticker symbol or shorthand, up to 5 chars.
            decimals: tokenDecimal, // The number of decimals in the token
            // image: tokenImage, // A string url of the token logo
          },
        },
      });
    } catch (error) {
      //
      console.log('errr-r>', error);
    }
  };

  return (
    <Button onClick={addMoonToMetaMask} size="sm" type="link">
      <Icon width="20px" /> Add to MetaMask
    </Button>
  );
}

export default AddToMetaMaskButton;
