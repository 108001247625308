import React, { useState } from "react";
import alphiePlaceholder from '../../images/alphie-unrevealed.gif';

function AlphieImageComponent({ src, alt }) {
  const [isLoading, setIsLoading] = useState(true);

  return (
    <>
      <img
        alt={alt}
        src={src}
        hidden={isLoading}
        style={{
          height: 'auto',
          width: '100%',
        }}
        onLoad={()=> setIsLoading(false)}
        onError={(e) => {
          setTimeout(() => {
            e.target.onerror = null; 
            e.target.src = src;
          }, 3000);
        }}
      />
      <img
        alt={alt}
        src={alphiePlaceholder}
        hidden={!isLoading}
        style={{
          height: 'auto',
          width: '100%',
        }}
      />
    </>
  );
}

export default AlphieImageComponent;