import React from "react";

import LoginSection from "../components/LoginSection";

function LoginPage(props) {
  return (
    <LoginSection
      textColor="white"
      variant="dark"
      title="Wallet"
      subtitle=""
    />
  );
}

export default LoginPage;
