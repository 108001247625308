import { useMemo } from 'react'

import useActiveWeb3React from './useActiveWeb3React';
import { getMoonTokenContract, getErc721Contract, getErc20Contract, getNFTContract, getDevWalletContract } from './helpers';
import { simpleRpcProvider } from '../util/providers';

export const useMoonToken = (address) => {
  const { library, account } = useActiveWeb3React();
  const signerOrProvider = account ? library.getSigner() : simpleRpcProvider;  

  return useMemo(() => getMoonTokenContract(address, signerOrProvider), [address, signerOrProvider]);
}

export const useERC721 = (address) => {
  const { library, account } = useActiveWeb3React();
  const signerOrProvider = account ? library.getSigner() : simpleRpcProvider;  

  return useMemo(() => getErc721Contract(address, signerOrProvider), [address, signerOrProvider]);
}

export const useERC20 = (address) => {
  const { library, account } = useActiveWeb3React();
  const signerOrProvider = account ? library.getSigner() : simpleRpcProvider;  

  return useMemo(() => getErc20Contract(address, signerOrProvider), [address, signerOrProvider]);
}

export const useNFT = (address) => {
  const { library, account } = useActiveWeb3React();
  const signerOrProvider = account ? library.getSigner() : simpleRpcProvider;  

  return useMemo(() => getNFTContract(address, signerOrProvider), [address, signerOrProvider]);
}

export const useDevWallet = (address) => {
  const { library, account } = useActiveWeb3React();
  const signerOrProvider = account ? library.getSigner() : simpleRpcProvider;  

  return useMemo(() => getDevWalletContract(address, signerOrProvider), [address, signerOrProvider]);
}
