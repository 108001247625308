import React, { useContext, useState } from "react";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import Chip from "@mui/material/Chip";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Spinner from "react-bootstrap/Spinner";
import { useMediaQuery } from "react-responsive";
import { useToasts } from "react-toast-notifications";

import { Link } from "../../util/router.js";
import { joinCampaign, getAccount } from "../../api";
import { MAX_ENTRIES } from "../../config/constant";
import { dropTypes, dropTypesLabel } from "../../enums/dropTypes";
import {
  getEntriesByMoonAccount,
  getTotalEntries,
} from "../../models/campaign";
import { MoonAccountContext } from "../../providers/MoonAccountProvider";
import ShopContext from "../../providers/ShopContext";

function JoinNowModal({
  open,
  onClose,
  campaign,
  account,
  currentPrice,
  remainingText,
}) {
  const shopcontext = React.useContext(ShopContext);
  const { primaryColor, secondaryColor, tokenSymbol } = shopcontext;

  const isMobile = useMediaQuery({ maxWidth: 767 });
  const [userBoughtEntries, setUserBoughtEntries] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const moonAccount = useContext(MoonAccountContext);
  const { addToast } = useToasts();

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: isMobile ? "80%" : 400,
    backgroundColor: "white",
    boxShadow: "5px 5px 15px 5px rgba(0,0,0,0.15)",
    borderRadius: "12px",
  };

  const onJoinCampaign = async () => {
    setIsLoading(true);
    try {
      await joinCampaign(account, campaign._id, userBoughtEntries);
      const moonAccount = await getAccount(account);
      const message = `You have entered into "${
        campaign.projectName
      } campaign" for ${
        userBoughtEntries * (currentPrice ?? campaign.pricePerEntry)
      } $${tokenSymbol}. You have ${moonAccount.moon[0].moonBalance.toFixed(
        3,
      )} $${tokenSymbol} left`;
      addToast(message, {
        appearance: "info",
        autoDismiss: true,
      });
      onClose();
      setIsLoading(false);
    } catch (error) {
      addToast(error.reason ? error.reason : error.message, {
        appearance: "error",
        autoDismiss: true,
      });
      onClose();
      setIsLoading(false);
    }
  };

  const getCurrentUserEntries = () => {
    const counts = {};
    campaign.entries.forEach(function (x) {
      counts[x.id] = (counts[x.id] || 0) + 1;
    });
    return counts;
  };

  const onClickSetMaxEntries = async () => {
    const counts = getCurrentUserEntries();
    setUserBoughtEntries(
      MAX_ENTRIES - (counts[moonAccount.account.discordId] || 0),
    );
  };

  return (
    <>
      {campaign ? (
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={open}
          onClose={onClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
          style={{
            borderRadius: "12px",
          }}
        >
          <Container style={style}>
            <Row>
              <Col
                xs={12}
                className="text-center"
                style={{
                  padding: "20px",
                  borderRadius: "8px 8px 0px 0px",
                  background: `linear-gradient(63deg, ${primaryColor} 0%, ${secondaryColor} 100%)`,
                }}
              >
                <h2 style={{ color: "white" }}>{campaign.projectName}</h2>
                <h6
                  style={{
                    color: "white",
                  }}
                >
                  {dropTypesLabel[campaign.dropType]}
                </h6>
                {campaign.dropType === dropTypes.raffle && (
                  <Chip
                    label={`Current Entries: ${getEntriesByMoonAccount(
                      campaign,
                      moonAccount,
                    )}/${getTotalEntries(campaign)}`}
                    size="small"
                    margin={1}
                    style={{
                      background: "white",
                      marginRight: "5px",
                    }}
                  />
                )}
                {(campaign.dropType === dropTypes.FCFS ||
                  campaign.dropType === dropTypes.dutch) && (
                  <Chip
                    label={`${campaign.entries.length}/${campaign.wlAvailable} Claimed`}
                    size="small"
                    style={{
                      background: "white",
                      marginRight: "5px",
                    }}
                  />
                )}
                <Chip
                  label={`Price: ${
                    currentPrice ?? campaign.pricePerEntry
                  } $${tokenSymbol}`}
                  size="small"
                  style={{
                    background: "white",
                    // marginRight: '5px',
                  }}
                />
                {campaign.dropType === dropTypes.dutch &&
                  currentPrice > campaign.endingPrice && (
                    <Chip
                      label={`Price decrease in: ${remainingText ?? "N/A"}`}
                      size="small"
                      style={{
                        background: "white",
                        margin: "5px",
                      }}
                    />
                  )}
              </Col>
              <Col className="text-center mt-4 mb-4">
                <ul className="text-left">
                  {campaign.requireDiscord && (
                    <li>
                      <p>
                        You must join the{" "}
                        <a
                          href={campaign.discordUrl}
                          style={{ textDecoration: "underline" }}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <b>{campaign.discordServerName} Discord</b>
                        </a>{" "}
                        {campaign.discordRoleName && campaign.discordRoleId ? (
                          <span>
                            with{" "}
                            <b
                              style={{
                                textDecoration: "underline",
                              }}
                            >
                              {campaign.discordRoleName}
                            </b>{" "}
                            role
                          </span>
                        ) : null}{" "}
                        before entering this one!
                      </p>
                    </li>
                  )}

                  {campaign.requireTwitter && (
                    <li>
                      <p>
                        You must follow the{" "}
                        <a
                          href={`https://twitter.com/${campaign.twitterScreenName.replace(
                            "@",
                            "",
                          )}`}
                          style={{ textDecoration: "underline" }}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <b>{campaign.twitterScreenName} Twitter</b>
                        </a>{" "}
                        before entering this one!
                      </p>
                    </li>
                  )}

                  {campaign.requireEthBalance && (
                    <li>
                      <p>
                        You must have{" "}
                        <b
                          style={{
                            textDecoration: "underline",
                          }}
                        >
                          {campaign.ethBalanceMin} ETH
                        </b>{" "}
                        in your wallet before entering this one!
                      </p>
                    </li>
                  )}

                  <li>
                    <p>
                      You will be paying{" "}
                      <b>
                        {currentPrice ?? campaign.pricePerEntry} ${tokenSymbol}
                      </b>
                      <br /> to enter <b>{campaign.projectName} campaign</b>.
                      <br /> 'OK' to proceed.
                    </p>
                  </li>
                </ul>

                {campaign.dropType === dropTypes.raffle && (
                  <Form.Group className="mb-2" controlId="formBasicEmail">
                    <InputGroup className="mb-3">
                      <Form.Control
                        placeholder={`Enter $${tokenSymbol} Amount`}
                        value={userBoughtEntries}
                        onChange={({ target: { value } }) =>
                          setUserBoughtEntries(value)
                        }
                        type="number"
                        min={1}
                        max={MAX_ENTRIES}
                        style={{
                          fontSize: "24px",
                          fontWeight: "600",
                          borderRadius: "8px 0px 0px 8px",
                          border: "1px solid black",
                        }}
                      />
                      <Button
                        style={{
                          fontWeight: "600",
                          borderRadius: "0px 8px 8px 0px",
                          border: "1px solid black",
                        }}
                        size="sm"
                        onClick={onClickSetMaxEntries}
                      >
                        Max
                      </Button>
                    </InputGroup>
                  </Form.Group>
                )}

                <div className="mt-4">
                  <Button
                    variant="light"
                    style={{
                      marginRight: "5px",
                      fontWeight: "600",
                    }}
                    onClick={onClose}
                    disabled={isLoading}
                  >
                    Cancel
                  </Button>

                  <Button
                    style={{
                      background: `linear-gradient(63deg, ${primaryColor} 0%, ${secondaryColor} 100%)`,
                      border: "transparent",
                      fontWeight: "600",
                    }}
                    onClick={onJoinCampaign}
                    disabled={
                      !(moonAccount?.account?.discordId ?? null) ||
                      userBoughtEntries <= 0 ||
                      isLoading
                    }
                  >
                    {isLoading && (
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                        style={{
                          marginRight: "7px",
                          marginTop: "-5px",
                          position: "relative",
                          top: "-2px",
                        }}
                      />
                    )}

                    {isLoading ? "Joining..." : "OK"}
                  </Button>
                </div>
                {moonAccount.account.discordId === null ||
                moonAccount.account.discordId === undefined ? (
                  <p
                    style={{
                      color: "red",
                      marginTop: "10px",
                      fontSize: "13px",
                    }}
                  >
                    You need to link your Discord before joining this campaign.
                    Please go to{" "}
                    <Link
                      to="/account"
                      className="p"
                      style={{
                        textDecoration: "underline",
                        textWeight: "700",
                        color: "red",
                      }}
                    >
                      Account Page
                    </Link>{" "}
                    to link it.
                  </p>
                ) : null}
              </Col>
            </Row>
          </Container>
        </Modal>
      ) : null}
    </>
  );
}

export default JoinNowModal;
