import { useCallback } from 'react';
import { useToasts } from 'react-toast-notifications';
import { UnsupportedChainIdError } from '@web3-react/core';
import { NoEthereumProviderError, UserRejectedRequestError as UserRejectedRequestErrorInjected, } from '@web3-react/injected-connector';
import { UserRejectedRequestError as UserRejectedRequestErrorWalletConnect, WalletConnectConnector, } from '@web3-react/walletconnect-connector';
import { connectorsByName } from '../util/web3React';
import useActiveWeb3React from './useActiveWeb3React';
import { setupNetwork } from '../util/wallet';

import { CONNECTOR_LOCAL_STORAGE_KEY, WEB3_TOKEN_KEY } from '../config/constant';


const useAuth = () => {
    const { chainId, activate, deactivate } = useActiveWeb3React();
    const { addToast } = useToasts();

    const login = useCallback(async (connectorID) => {
      const connector = connectorsByName[connectorID];
      if (connector) {
        activate(connector, async (error) => {
          if (error instanceof UnsupportedChainIdError) {
            const hasSetup = await setupNetwork();
            if (hasSetup) {
                activate(connector);
            }
          } else {
            window.localStorage.removeItem(CONNECTOR_LOCAL_STORAGE_KEY)
            if (error instanceof NoEthereumProviderError) {
              addToast('Provider Error: No provider was found', {
                appearance: 'error',
                autoDismiss: true,
              });

              return;
            }
            else if (error instanceof UserRejectedRequestErrorInjected ||
                error instanceof UserRejectedRequestErrorWalletConnect) {
                if (connector instanceof WalletConnectConnector) {
                    const walletConnector = connector;
                    walletConnector.walletConnectProvider = null;
                }
                addToast('Authorization Error: Please authorize to access your account', {
                  appearance: 'error',
                  autoDismiss: true,
                });

                return;
            }
            else {
              addToast(error.name + ' - ' + error.message, {
                appearance: 'error',
                autoDismiss: true,
              });

              return;
            }
          }
        });
      }
      else {
        addToast('Unable to find connector - The connector config is wrong', {
          appearance: 'error',
          autoDismiss: true,
        });

        return;
      }
    }, [activate]);
    
    const logout = useCallback(async (account) => {
      window.localStorage.removeItem(CONNECTOR_LOCAL_STORAGE_KEY);
      window.localStorage.removeItem(`${WEB3_TOKEN_KEY}-${account}`);

      await deactivate();
      // This localStorage key is set by @web3-react/walletconnect-connector
      if (window.localStorage.getItem('walletconnect')) {
        connectorsByName.walletconnect.close()
        connectorsByName.walletconnect.walletConnectProvider = null
      }
      // if (chainId) {
      //     dispatch(clearAllTransactions({ chainId }));
      // }
    }, [deactivate, chainId]);
    return { login, logout };
};
export default useAuth;