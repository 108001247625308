import axios from "axios";
import React, { useEffect, useState } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

import Section from "./Section";
import { DISCORD_LOGIN_URL } from "../../config/constant";
import { useWeb3React } from "@web3-react/core";
import { useLocation, useParams } from "../../util/router";
import CryingAlphie from "../Shared/CryingAlphie";
import SadAlphie from "../Shared/SadAlphie";
import ShopContext from "../../providers/ShopContext";

import { connectorsByName } from "../../util/web3React";

import { ALPHIEMOON_BE_BASE_URL } from "../../config/constant";

function ConnectAccountErrorSection(props) {
  const shopcontext = React.useContext(ShopContext);
  const { primaryColor, secondaryColor } = shopcontext;

  const { account, active, activate } = useWeb3React();

  const { platform } = useParams();
  const { search } = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState();

  useEffect(() => {
    (async () => {
      if (!active) {
        setTimeout(async () => {
          const connectorID = "injected";
          const connector = connectorsByName[connectorID];
          try {
            await activate(connector);
          } catch (error) {
            console.log("error", error);
          }
        }, 500);
      }
    })();
  }, [active, activate]);

  useEffect(() => {
    if (account) {
      init();
    }
  }, [account]);

  const init = async () => {
    const message = new URLSearchParams(search).get("message");
    setErrorMessage(message);
    setIsLoading(false);
  };

  const loginTwitter = () => {
    (async () => {
      try {
        //OAuth Step 1
        const response = await axios({
          url: `${ALPHIEMOON_BE_BASE_URL}/api/twitter/oauth/request_token?state=${account.id}`,
          method: "POST",
        });

        const { oauth_token } = response.data;
        //Oauth Step 2
        window.location.href = `https://api.twitter.com/oauth/authenticate?oauth_token=${oauth_token}`;
      } catch (error) {
        console.error(error);
      }
    })();
  };

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Section
        bg={props.bg}
        textColor={props.textColor}
        size={props.size}
        bgImage={props.bgImage}
        bgImageOpacity={props.bgImageOpacity}
        style={{
          paddingBottom: "0px !important",
          background: `linear-gradient(63deg, ${primaryColor} 0%, ${secondaryColor} 100%)`,
        }}
      >
        <Container style={{ padding: "100px 35px 0px" }}>
          <Row className="align-items-center" style={{ padding: "70px 0px" }}>
            {!isLoading && active ? (
              <>
                {errorMessage ? (
                  <Col
                    md={12}
                    className="text-center d-flex align-items-center justify-content-center"
                    style={{
                      background: "rgba(0,0,0,0.5)",
                      padding: "60px",
                      borderRadius: "8px",
                    }}
                  >
                    <div>
                      <div>
                        <SadAlphie size={250} />
                      </div>
                      <h2>Sorry...</h2>
                      <h5 className="mb-4">{errorMessage}</h5>
                      {platform === "discord" ? (
                        <a
                          href={`${DISCORD_LOGIN_URL}&state=${account}`}
                          rel="noopener noreferrer"
                        >
                          <Button
                            style={{
                              marginRight: "10px",
                              background: `linear-gradient(63deg, ${primaryColor} 0%, ${secondaryColor} 100%)`,
                              border: "transparent",
                            }}
                          >
                            Retry
                          </Button>
                        </a>
                      ) : (
                        <Button
                          style={{
                            marginRight: "10px",
                            background: `linear-gradient(63deg, ${primaryColor} 0%, ${secondaryColor} 100%)`,
                            border: "transparent",
                          }}
                          onClick={loginTwitter}
                        >
                          Retry
                        </Button>
                      )}
                    </div>
                  </Col>
                ) : (
                  <Col
                    md={12}
                    className="text-center d-flex align-items-center justify-content-center"
                    style={{
                      background: "rgba(0,0,0,0.5)",
                      padding: "60px",
                      borderRadius: "8px",
                    }}
                  >
                    <div>
                      <div>
                        <CryingAlphie size={250} />
                      </div>
                      <h2>Something Wrong...</h2>
                      <h5>Invalid Request</h5>
                    </div>
                  </Col>
                )}
              </>
            ) : null}
          </Row>
        </Container>
      </Section>
    </>
  );
}

export default ConnectAccountErrorSection;
