import axios from "axios";
import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { Card, CardContent } from "@mui/material";

import { ALPHIEMOON_BE_BASE_URL } from "../../config/constant";

export default function TwitterAccountComponent({
  moonAccount,
  getMoonAccount,
}) {
  let { account } = moonAccount;

  const login = () => {
    (async () => {
      try {
        //OAuth Step 1
        const response = await axios({
          url: `${ALPHIEMOON_BE_BASE_URL}/api/twitter/oauth/request_token?state=${account.id}`,
          method: "POST",
        });

        const { oauth_token } = response.data;
        //Oauth Step 2
        window.location.href = `https://api.twitter.com/oauth/authenticate?oauth_token=${oauth_token}`;
      } catch (error) {
        console.error(error);
      }
    })();
  };

  const logout = () => {
    (async () => {
      try {
        await axios({
          url: `${ALPHIEMOON_BE_BASE_URL}/api/twitter/logout`,
          method: "POST",
          data: {
            oauth_access_token: account.twitterAccessToken,
            oauth_access_token_secret: account.twitterAccesstokenSecret,
          },
        });
        await getMoonAccount();

        console.log(account);
      } catch (error) {
        console.error(error);
      }
    })();
  };

  return (
    <Card>
      <CardContent style={{ padding: "2.8em 2.2em" }}>
        <Container>
          <Row>
            <Col style={{ marginBottom: "20px" }}>
              <h2>Twitter Account</h2>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <h5 className="mb-2">Link your Twitter account</h5>
              {account.twitterAccessToken ? (
                <>
                  {Date.now() < account.twitterExpirationDate ? (
                    <>
                      <Button
                        style={{ marginRight: "10px" }}
                        size="sm"
                        variant="success"
                      >
                        Connected
                      </Button>
                      <Button
                        style={{ marginRight: "10px" }}
                        size="sm"
                        variant="link"
                        onClick={logout}
                      >
                        Revoke
                      </Button>
                    </>
                  ) : (
                    <Button
                      style={{ marginRight: "10px" }}
                      size="sm"
                      onClick={login}
                    >
                      Re-Link
                    </Button>
                  )}
                </>
              ) : (
                <Button
                  style={{ marginRight: "10px" }}
                  size="sm"
                  onClick={login}
                >
                  Link
                </Button>
              )}
            </Col>
          </Row>
        </Container>
      </CardContent>
    </Card>
  );
}
