import React from "react";
import { Chip, Stack, Typography } from "@mui/material";
import { DateTime } from "luxon"
import { shorter } from "../util/util";
import Xarrow from "react-xarrows";
import { Link } from "react-router-dom";


export const TABLE_FIELDS = [
    {
        label: "Date",
        render: ({created}) => {
            return (<strong>{DateTime.fromISO(created).toLocaleString(DateTime.DATETIME_MED)}</strong>);
        }
    },
    {
        label: "Transcation Detail",
        render: ({from, to, quantity, transactionType, value, campaignId, description}) => {
            const fromRef = React.createRef();
            const toRef = React.createRef();

            return (
                <Stack direction="column" spacing={1}>
                    <strong>{transactionType}</strong>
                    {campaignId && <Typography variant="caption"><Chip size="small" label={ `${quantity} entries` } color="secondary" /> in <Link to={`/campaigns/${campaignId}`}><Chip size="small" label={ campaignId } color="default" style={{ cursor: 'pointer' }} /></Link></Typography> }
                    <Stack direction="row" spacing={2}>
                        <Chip ref={fromRef} size="small" label={ from === 'WhaleTogether' ? from : shorter(from) } color="default" variant="outlined" />
                        <Chip size="small" label={`${value} $MOON`} style={{zIndex: 20}} color="primary" />
                        <Chip ref={toRef} size="small" label={ to === 'WhaleTogether' ? to : shorter(to) } color="default" variant="outlined" />
                    </Stack>
                    <Xarrow start={fromRef} end={toRef} color="#999999" dashness={false} strokeWidth={1} tailSize={1} headShape='arrow' />
                </Stack>
            );
        }
    },
    {
        label: "Description",
        render: ({description}) => {
            return description
        }
    },
];