import { useState } from "react";
import { Button } from "react-bootstrap";
import WalletModal from "../WalletModal";

function ConnectWalletButton({buttonColor, buttonSize, error}) { 
    const [modalState, setModalState] = useState(false);
    const toggleModal = () => setModalState(!modalState);
    return (
        <div className="text-center">
            <WalletModal open={modalState} onClose={toggleModal} />
            <Button variant={buttonColor} onClick={toggleModal} size={buttonSize || "lg"}>
                Connect Wallet
            </Button>
            { error && error.name === 'UnsupportedChainIdError' && (
                <h6 style={{ marginTop: '10px', color: 'red' }}>
                    <u>Please change to Mainnet!</u>
                </h6>
            ) }
        </div>
    );
}

export default ConnectWalletButton