import React from "react";

import AdminSection from "../components/AdminSection";

function AdminPage(props) {
  const { id } = props.match.params;

  return (
    <AdminSection
      textColor="white"
      variant="dark"
      title="Admin"
      subtitle=""
      id={id}
    />
  );
}

export default AdminPage;
