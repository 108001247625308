import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { DISCORD_LOGIN_URL } from "../../config/constant";

import { revokeTokenSelf } from "../../api";
import { Card, CardContent } from "@mui/material";

function SocialAccountComponent({ moonAccount, getMoonAccount }) {
  const { account } = moonAccount;

  const revoke = async () => {
    await revokeTokenSelf(account.id, account.discordAccessToken);
    await getMoonAccount();
  };

  return (
    <Card>
      <CardContent style={{ padding: "2.8em 2.2em" }}>
        <Container>
          <Row>
            <Col style={{ marginBottom: "20px" }}>
              <h2>Discord Account</h2>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <h5 className="mb-2">Link your Discord account</h5>

              {account.discordAccessToken ? (
                <>
                  {Date.now() < account.discordExpirationDate ? (
                    <>
                      <Button
                        style={{ marginRight: "10px" }}
                        size="sm"
                        variant="success"
                      >
                        Connected
                      </Button>
                      <Button
                        style={{ marginRight: "10px" }}
                        size="sm"
                        variant="link"
                        onClick={revoke}
                      >
                        Revoke
                      </Button>
                    </>
                  ) : (
                    <a
                      href={`${DISCORD_LOGIN_URL}&state=${account.id}`}
                      rel="noopener noreferrer"
                    >
                      <Button style={{ marginRight: "10px" }} size="sm">
                        Re-Link
                      </Button>
                    </a>
                  )}
                </>
              ) : (
                <a
                  href={`${DISCORD_LOGIN_URL}&state=${account.id}`}
                  rel="noopener noreferrer"
                >
                  <Button style={{ marginRight: "10px" }} size="sm">
                    Link
                  </Button>
                </a>
              )}
            </Col>
          </Row>
        </Container>
      </CardContent>
    </Card>
  );
}

export default SocialAccountComponent;
