import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { useMediaQuery } from "react-responsive";
import Backdrop from "@mui/material/Backdrop";
import SectionHeader from "../SectionHeader";
import useActiveWeb3React from "../../hooks/useActiveWeb3React";
import useAuth from "../../hooks/useAuth";
import { getAccount, getShop } from "../../api";
import { accountTypes } from "../../enums/accountTypes";
import _ from "lodash";
import { createShop, updateShopTheme } from "../../api/shop";
import {
  Card,
  CardContent,
  MenuItem,
  TextField,
  CircularProgress,
  Switch,
  FormGroup,
  FormControlLabel,
  ToggleButtonGroup,
  ToggleButton,
  Button,
  Divider,
  InputLabel,
  Box,
} from "@mui/material";

import * as yup from "yup";
import { useFormik } from "formik";
import ShopContext from "../../providers/ShopContext";
import ColorPicker from "./ColorPicker";
import FormControl from "@mui/material/FormControl";
import { useToasts } from "react-toast-notifications";
import { SERVER_ID } from "../../config/constant";

export default function CustomThemeComponent() {
  const { addToast } = useToasts();
  const shopcontext = React.useContext(ShopContext);
  const {
    initailized,
    erc20Address,
    setErc20Address,
    devWallet,
    setDevWallet,
    raffleRole,
    setRaffleRole,
    configChannel,
    setConfigChannel,
    showCompany,
    setShowCompany,
    textColor,
    title,
    setTitle,
    company,
    setCompany,
    logoUrl,
    setLogoUrl,
    bgImage,
    bgImageOpacity,
    primaryColor,
    setPrimaryColor,
    secondaryColor,
    setSecondaryColor,
    twitterLink,
    setTwitterLink,
    discordLink,
    setDiscordLink,
    loginBgUrl,
    setLoginBgUrl,
    loginLogoUrl,
    setLoginLogoUrl,
    tokenContractAddress,
    setTokenContractAddress,
    tokenCurrency,
    setTokenCurrency,
    tokenLogo,
    setTokenLogo,
    tokenBurnAddress,
    setTokenBurnAddress,
    tokenSymbol,
    setTokenSymbol,
    tokenDecimal,
    setTokenDecimal,
    updateShop,
  } = shopcontext;

  dayjs.extend(relativeTime);

  const { active, account } = useActiveWeb3React();
  const [moonAccount, setMoonAccount] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [shop, setShop] = useState();
  const { logout } = useAuth();

  const ETH_ADDRESS_REGEX = /^0x[a-fA-F0-9]{40}$/;

  const validationSchema = yup.object({
    erc20Address: yup
      .string("Enter your ERC20 Address")
      .required("ERC20 Address is required")
      .matches(ETH_ADDRESS_REGEX, "Not a valid ETH address"),
    devWallet: yup
      .string("Enter Dev wallet")
      .required("Dev wallet is required")
      .matches(ETH_ADDRESS_REGEX, "Not a valid ETH address"),
    raffleRole: yup
      .string("Enter your Raffle Role ID")
      .required("Raffle Role ID is required"),
    configChannel: yup
      .string("Enter your Config Channel ID")
      .required("Config Channel ID is required"),
    title: yup
      .string("Enter your shop title")
      .required("Shop title is required"),
  });

  const formik = useFormik({
    initialValues: {
      id: SERVER_ID,
      erc20Address: erc20Address,
      devWallet: devWallet,
      raffleRole: raffleRole,
      configChannel: configChannel,
      show_company: showCompany,
      title: title,
      company: company,
      logo_url: logoUrl,
      login_bg_url: loginBgUrl,
      login_logo_url: loginLogoUrl,
      discord_link: discordLink,
      twitter_link: twitterLink,
      token_contract_address: erc20Address,
      token_burn_address: tokenBurnAddress,
      token_symbol: tokenSymbol,
      token_logo: tokenLogo,
      token_decimal: tokenDecimal,
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      //console.log(setSubmitting);
      values.primary_color = primaryColor;
      values.secondary_color = secondaryColor;
      values.token_contract_address = values.erc20Address;
      //alert(JSON.stringify(values, null, 2));
      setSubmitting(true);
      if (!initailized) {
        await createShop(account, values);
      } else {
        await updateShopTheme(account, values);
      }
      updateShop();
      //resetForm();
      setSubmitting(false);

      const message = "Update shop theme successfully!";
      addToast(message, {
        appearance: "info",
        autoDismiss: true,
      });
    },
  });

  useEffect(() => {
    const init = async () => {
      try {
        await getMoonAccount();
        setIsLoading(false);
      } catch (e) {
        logout(account);
      }
    };

    const getMoonAccount = async () => {
      try {
        const moonAccount = await getAccount(account);
        setMoonAccount(moonAccount);
      } catch (error) {
        console.log("error:", error);
      }
    };

    if (account) {
      init();
    }
  }, [account, logout]);

  const init = async () => {
    try {
      await getMyShop();
      setIsLoading(false);
    } catch (e) {
      logout(account);
    }
  };

  const getMyShop = async () => {
    try {
      let data = await getShop(account);
      if (_.isNull(data.shop)) {
        setShop({
          id: SERVER_ID,
        });
      } else {
        setShop(data.shop);
      }
    } catch (error) {
      console.log("error:", error);
    }
  };

  const popover = {
    position: "absolute",
    zIndex: "2",
  };
  const cover = {
    position: "fixed",
    top: "0px",
    right: "0px",
    bottom: "0px",
    left: "0px",
  };

  //console.log(showCompany);

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Container className="text-center">
        <SectionHeader
          className="header-font above-fold-header"
          title="Custom Theme"
          size={1}
        />
        {!isLoading &&
        active &&
        moonAccount &&
        moonAccount.account.type === accountTypes.admin ? (
          <Card>
            <CardContent>
              <form onSubmit={formik.handleSubmit} noValidate>
                <TextField
                  fullWidth
                  id="id"
                  name="id"
                  label="SERVER ID"
                  value={formik.values.id}
                  disabled={true}
                />
                <TextField
                  fullWidth
                  required={true}
                  id="erc20Address"
                  name="erc20Address"
                  label="ERC20 Address"
                  value={formik.values.erc20Address}
                  onChange={(e) => {
                    //console.log(e);
                    formik.handleChange(e);
                    setErc20Address(e.target.value);
                  }}
                  error={
                    formik.touched.erc20Address &&
                    Boolean(formik.errors.erc20Address)
                  }
                  helperText={
                    formik.touched.erc20Address && formik.errors.erc20Address
                  }
                />
                <TextField
                  fullWidth
                  required={true}
                  id="devWallet"
                  name="devWallet"
                  label="Dev Wallet Address"
                  value={formik.values.devWallet}
                  onChange={(e) => {
                    //console.log(e);
                    formik.handleChange(e);
                    setDevWallet(e.target.value);
                  }}
                  error={
                    formik.touched.devWallet && Boolean(formik.errors.devWallet)
                  }
                  helperText={
                    formik.touched.devWallet && formik.errors.devWallet
                  }
                />
                <TextField
                  fullWidth
                  required={true}
                  id="raffleRole"
                  name="raffleRole"
                  label="Raffle Role ID"
                  value={formik.values.raffleRole}
                  onChange={(e) => {
                    //console.log(e);
                    formik.handleChange(e);
                    setRaffleRole(e.target.value);
                  }}
                  error={
                    formik.touched.raffleRole &&
                    Boolean(formik.errors.raffleRole)
                  }
                  helperText={
                    formik.touched.raffleRole && formik.errors.raffleRole
                  }
                />
                <TextField
                  fullWidth
                  required={true}
                  id="configChannel"
                  name="configChannel"
                  label="Config Channel ID"
                  value={formik.values.configChannel}
                  onChange={(e) => {
                    //console.log(e);
                    formik.handleChange(e);
                    setConfigChannel(e.target.value);
                  }}
                  error={
                    formik.touched.configChannel &&
                    Boolean(formik.errors.configChannel)
                  }
                  helperText={
                    formik.touched.configChannel && formik.errors.configChannel
                  }
                />
                <TextField
                  fullWidth
                  required={true}
                  id="title"
                  name="title"
                  label="Title"
                  value={formik.values.title}
                  onChange={(e) => {
                    //console.log(e);
                    formik.handleChange(e);
                    setTitle(e.target.value);
                  }}
                  error={formik.touched.title && Boolean(formik.errors.title)}
                  helperText={formik.touched.title && formik.errors.title}
                />
                <Row>
                  <Col md={8} xs={12}>
                    <TextField
                      fullWidth
                      id="company"
                      name="company"
                      label="Company"
                      value={formik.values.company}
                      onChange={(e) => {
                        formik.handleChange(e);
                        setCompany(e.target.value);
                      }}
                      error={
                        formik.touched.company && Boolean(formik.errors.company)
                      }
                      helperText={
                        formik.touched.company && formik.errors.company
                      }
                    />
                  </Col>
                  <Col md={4} xs={12}>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Switch
                            id="show_company"
                            name="show_company"
                            label="Show Company"
                            checked={formik.values.show_company}
                            onChange={(e) => {
                              //console.log(e.target.checked);
                              formik.handleChange(e);
                              setShowCompany(e.target.checked);
                            }}
                          />
                        }
                        label="Show Company"
                      />
                    </FormGroup>
                  </Col>
                </Row>

                <TextField
                  fullWidth
                  id="logo_url"
                  name="logo_url"
                  label="Logo URL"
                  value={formik.values.logo_url}
                  onChange={(e) => {
                    formik.handleChange(e);
                    setLogoUrl(e.target.value);
                  }}
                  error={
                    formik.touched.logo_url && Boolean(formik.errors.logo_url)
                  }
                  helperText={
                    formik.touched.logo_url && formik.errors.logo_url
                      ? formik.errors.logo_url
                      : "Recommend size 200x200 Pixel"
                  }
                />
                <TextField
                  fullWidth
                  id="login_bg_url"
                  name="login_bg_url"
                  label="Login Background URL"
                  value={formik.values.login_bg_url}
                  onChange={(e) => {
                    formik.handleChange(e);
                    setLoginBgUrl(e.target.value);
                  }}
                  error={
                    formik.touched.login_bg_url &&
                    Boolean(formik.errors.login_bg_url)
                  }
                  helperText={
                    formik.touched.login_bg_url && formik.errors.login_bg_url
                  }
                />
                <TextField
                  fullWidth
                  id="login_logo_url"
                  name="login_logo_url"
                  label="Login Logo URL"
                  value={formik.values.login_logo_url}
                  onChange={(e) => {
                    formik.handleChange(e);
                    setLoginLogoUrl(e.target.value);
                  }}
                  error={
                    formik.touched.login_logo_url &&
                    Boolean(formik.errors.login_logo_url)
                  }
                  helperText={
                    formik.touched.login_logo_url &&
                    formik.errors.login_logo_url
                  }
                />
                <FormGroup row={true}>
                  <FormControlLabel
                    style={{ padding: 15 }}
                    control={
                      <ColorPicker
                        id="component-primary-color"
                        color={primaryColor}
                        onChange={(color) => {
                          //console.log(color);
                          setPrimaryColor(color);
                        }}
                      />
                    }
                    label=" Primary Color"
                  />
                  <FormControlLabel
                    style={{ padding: 15 }}
                    control={
                      <ColorPicker
                        id="component-primary-color"
                        color={secondaryColor}
                        onChange={(color) => {
                          //console.log(color);
                          setSecondaryColor(color);
                        }}
                      />
                    }
                    label=" Seconday Color"
                  />
                </FormGroup>
                <TextField
                  fullWidth
                  id="discord_link"
                  name="discord_link"
                  label="Discord Link"
                  value={formik.values.discord_link}
                  onChange={(e) => {
                    formik.handleChange(e);
                    setDiscordLink(e.target.value);
                  }}
                  error={
                    formik.touched.discord_link &&
                    Boolean(formik.errors.discord_link)
                  }
                  helperText={
                    formik.touched.discord_link && formik.errors.discord_link
                  }
                />
                <TextField
                  fullWidth
                  id="twitter_link"
                  name="twitter_link"
                  label="Twitter Link"
                  value={formik.values.twitter_link}
                  onChange={(e) => {
                    formik.handleChange(e);
                    setTwitterLink(e.target.value);
                  }}
                  error={
                    formik.touched.twitter_link &&
                    Boolean(formik.errors.twitter_link)
                  }
                  helperText={
                    formik.touched.twitter_link && formik.errors.twitter_link
                  }
                />

                {/*
                <TextField
                  fullWidth
                  id="token_contract_address"
                  name="token_contract_address"
                  label="Token Contract Address"
                  value={formik.values.token_contract_address}
                  onChange={(e) => {
                    formik.handleChange(e);
                    setTokenContractAddress(e.target.value);
                  }}
                  error={
                    formik.touched.token_contract_address &&
                    Boolean(formik.errors.token_contract_address)
                  }
                  helperText={
                    formik.touched.token_contract_address &&
                    formik.errors.token_contract_address
                  }
                />
                  */}

                <TextField
                  fullWidth
                  id="token_burn_address"
                  name="token_burn_address"
                  label="Token Burn Address"
                  value={formik.values.token_burn_address}
                  onChange={(e) => {
                    formik.handleChange(e);
                    setTokenBurnAddress(e.target.value);
                  }}
                  error={
                    formik.touched.token_burn_address &&
                    Boolean(formik.errors.token_burn_address)
                  }
                  helperText={
                    formik.touched.token_burn_address &&
                    formik.errors.token_burn_address
                  }
                  disabled={true}
                />

                <TextField
                  fullWidth
                  id="token_symbol"
                  name="token_symbol"
                  label="Token Symbol"
                  value={formik.values.token_symbol}
                  onChange={(e) => {
                    formik.handleChange(e);
                    setTokenSymbol(e.target.value);
                  }}
                  error={
                    formik.touched.token_symbol &&
                    Boolean(formik.errors.token_symbol)
                  }
                  helperText={
                    formik.touched.token_symbol && formik.errors.token_symbol
                  }
                />

                <TextField
                  fullWidth
                  id="token_decimal"
                  name="token_decimal"
                  label="Token Decimal"
                  value={formik.values.token_decimal}
                  onChange={(e) => {
                    formik.handleChange(e);
                    setTokenDecimal(e.target.value);
                  }}
                  error={
                    formik.touched.token_decimal &&
                    Boolean(formik.errors.token_decimal)
                  }
                  helperText={
                    formik.touched.token_decimal && formik.errors.token_decimal
                  }
                />

                <TextField
                  fullWidth
                  id="token_logo"
                  name="token_logo"
                  label="Token Logo"
                  value={formik.values.token_logo}
                  onChange={(e) => {
                    formik.handleChange(e);
                    setTokenLogo(e.target.value);
                  }}
                  error={
                    formik.touched.token_logo &&
                    Boolean(formik.errors.token_logo)
                  }
                  helperText={
                    formik.touched.token_logo && formik.errors.token_logo
                  }
                />

                <Divider className="my-4"></Divider>
                <Button
                  size="large"
                  variant="contained"
                  fullWidth
                  type="submit"
                  className="mb-4"
                  disabled={false}
                >
                  UPDATE THEME
                </Button>
              </form>
            </CardContent>
          </Card>
        ) : (
          <Row>
            <Col xs={12} className="text-center">
              <h1>You're not allowed to be here</h1>
            </Col>
          </Row>
        )}
      </Container>
    </>
  );
}
