import React, { useState, useEffect } from "react";
import { scroller } from "react-scroll";
import { 
  Nav,
  Navbar,
  NavDropdown,
  Button
} from "react-bootstrap";
import Container from "react-bootstrap/Container";
import { LinkContainer } from "react-router-bootstrap";

import WalletModal from "../WalletModal";
import MoonIcon from "../Shared/MoonIcon";

import { getAccount } from '../../api';
import useActiveWeb3React from '../../hooks/useActiveWeb3React';
import useAuth from '../../hooks/useAuth';
import { shorter } from "../../util/util";
import ShopContext from "../../providers/ShopContext";

function NavbarCustom(props) {
  const shopcontext = React.useContext(ShopContext);
  const {
    initailized,
    showCompany
  } = shopcontext;
  const { account, active } = useActiveWeb3React();
  const { logout } = useAuth();
  const [isCollapse, setIsCollapse] = useState(true);
  const [modalState, setModalState] = useState(false);
  const [moonAccount, setMoonAccount] = useState();
  const toggleNavbar = () => {
    setIsCollapse(!isCollapse);
  }

  const onDisconnectWallet = () => {
    logout(account);
  }

  const scrollToSection = (section) => {
    // scroller.scrollTo(section, {
    //   duration: 800,
    //   delay: 100,
    //   smooth: true,
    //   offset: -250,
    // });
  }

  useEffect(() => {
    const getMoonAccount = async () => {
      try {
        const moonAccount = await getAccount(account);
        setMoonAccount(moonAccount);
      } catch (error) {
        console.log('error:', error);
      }
    }

    if (active && account) {
      getMoonAccount();
    }
  }, [active, account]);

  const toggleModal = () => {
    setModalState(!modalState);
  }

  return (
    <>
      <WalletModal open={modalState} onClose={toggleModal} />
      <Navbar 
        bg={props.bg} 
        variant={props.variant}
        expand={props.expand}
        fixed="top"
        style={{ padding: '10px' }}
      >
        <Container>
          <LinkContainer to="/" onClick={() => scrollToSection('herosection')}>
            <Navbar.Brand href="#home" className="mr-auto">
              <img
                className="d-inline-block align-center"
                src={props.logo}
                alt="Logo"
                height="50"
                style={{
                  borderRadius: '50%',
                  marginRight: '7px',
                  display: 'inline-block',
                }}
              />{'  '}
              <div style={{ display: 'inline-block', verticalAlign: 'middle' }}>
                <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                <h1 style={{ fontSize: '20px', marginBottom: '2px' }}>{props.title}</h1>
                {
                  showCompany && (<h6 style={{ fontSize: '13px', margin: '0px' }}>by {props.company}</h6>)
                }
                  
                </div>
              </div>
            </Navbar.Brand>
          </LinkContainer>

          <Navbar.Toggle aria-controls="navbar-nav" className="border-0" onClick={() => toggleNavbar()} />
          <Navbar.Collapse id="navbar-nav" className="justify-content-end">
            <Nav className="mr-1">
              {active ? (
                <>
                  <LinkContainer to='/campaigns'>
                    <Nav.Link className={'text-center text-sm-left'}>Campaigns</Nav.Link>
                  </LinkContainer>
                  <LinkContainer to='/account'>
                    <Nav.Link className={'text-center text-sm-left'}>My Account</Nav.Link>
                  </LinkContainer>
                  <LinkContainer to='/my-wins'>
                    <Nav.Link className={'text-center text-sm-left'}>My Wins</Nav.Link>
                  </LinkContainer>

                  {moonAccount ? (
                    <p className={'text-center text-sm-left'} style={{ color: 'white', padding: '8px 0px', marginBottom: '0px'}}>
                      <MoonIcon size={16}/> {moonAccount.moon[0].moonBalance.toFixed(3)}
                    </p>
                  ) : null}
                  <NavDropdown
                    title={`${shorter(account)}`} 
                    id="basic-nav-dropdown" 
                    style={{ marginLeft: '8px', border: 'solid 1px white', borderRadius: '5px', fontWeight: '600' }}
                    className="text-center"
                  >
                    {/* <LinkContainer to='/account' style={{ color: 'black', padding: '0.25rem 1.5rem', fontWeight: '400' }}>
                      <Nav.Link className={'text-left text-sm-left'}>My Account</Nav.Link>
                    </LinkContainer>
                    <LinkContainer to='/my-wins' style={{ color: 'black', padding: '0.25rem 1.5rem', fontWeight: '400' }}>
                    <Nav.Link className={'text-left text-sm-left'}>My Wins</Nav.Link>
                    </LinkContainer> */}
                    {moonAccount && moonAccount.account && moonAccount.account.type == "admin" && (
                      <LinkContainer to="/admin">
                        <NavDropdown.Item>Admin</NavDropdown.Item>
                      </LinkContainer>
                    )}
                    <LinkContainer to='/transactions'>
                      <NavDropdown.Item>
                        Transactions
                      </NavDropdown.Item>
                    </LinkContainer>
                    <NavDropdown.Divider />
                    <NavDropdown.Item
                      href="https://whaletogether.gitbook.io/guides"
                      target="_blank"
                    >
                      Help
                    </NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item
                      onClick={onDisconnectWallet}
                    >
                      Disconnect
                    </NavDropdown.Item>
                  </NavDropdown>
                </>
              ) : (
                <Button
                  style={{ 
                    marginLeft: '8px',
                    background: `linear-gradient(63deg, ${props.primaryColor} 0%, ${props.secondaryColor} 100%)`,
                    fontWeight: '600',
                  }} 
                  variant="primary"
                  onClick={toggleModal}
                  disabled={+ new Date('2021-10-28T09:00:00.000-06:00') > Date.now()}
                >Connect Wallet</Button>
              )}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default NavbarCustom;
