import React, { useEffect } from "react";
import { Web3ReactProvider } from "@web3-react/core";
import { IntlProvider } from "react-intl";
import { ToastProvider } from "react-toast-notifications";
import { Redirect } from "react-router-dom";

import "./../styles/global.scss";
import LoginPage from "./login";
import AccountPage from "./account";
import MyWinsPage from "./my-wins";
import CampaignsPage from "./campaigns";
import CampaignPage from "./campaign";
import CreateCampaignPage from "./create-campaign";
import ConnectAccountErrorPage from "./connect-account-error";
import AdminPage from "./admin";
import { Switch, Route, Router } from "./../util/router.js";
import NotFoundPage from "./not-found.js";
import "./../util/analytics.js";
import { QueryClientProvider } from "./../util/db.js";
import { getLibrary } from "./../util/web3React";
import MetamaskProvider from "../providers/MetamaskProvider";

import NavbarCustom from "../components/NavbarCustom";
import Footer from "./../components/Footer";
import logoWhite from "../images/AlphieMoon PFP-01.jpg";
import useMediaQuery from "@mui/material/useMediaQuery";
import { ThemeProvider } from "@mui/material";
import { theme } from "../config/muiTheme";
import TransactionsPage from "./transactions";
import CustomThemePage from "./custom-theme";
import ShopContext from "../providers/ShopContext";

function App(props) {
  const companyName = "WhaleTogether";
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
  const appTheme = React.useMemo(
    () => theme(prefersDarkMode ? "light" : "light"),
    [prefersDarkMode],
  );

  const shopcontext = React.useContext(ShopContext);
  const {
    initailized,
    textColor,
    title,
    company,
    logoUrl,
    bgImage,
    bgImageOpacity,
    primaryColor,
    secondaryColor,
    twitterLink,
    discordLink,
  } = shopcontext;

  return (
    <IntlProvider locale="en-US">
      <ToastProvider placement="bottom-left">
        <Web3ReactProvider getLibrary={getLibrary}>
          <QueryClientProvider>
            <ThemeProvider theme={appTheme}>
              <Router>
                <MetamaskProvider>
                  <NavbarCustom
                    bg="black"
                    variant="dark"
                    expand="md"
                    title={title}
                    company={company}
                    logo={logoUrl}
                    primaryColor={primaryColor}
                    secondaryColor={secondaryColor}
                  />
                  <Switch>
                    <Route exact path="/">
                      <Redirect to="/campaigns" />
                    </Route>
                    <Route exact path="/admin" component={AdminPage} />
                    <Route
                      exact
                      path="/custom-theme"
                      component={CustomThemePage}
                    />
                    <Route exact path="/account" component={AccountPage} />
                    <Route exact path="/my-wins" component={MyWinsPage} />
                    <Route
                      exact
                      path="/campaigns/new"
                      component={CreateCampaignPage}
                    />
                    <Route exact path="/campaigns" component={CampaignsPage} />
                    <Route
                      exact
                      path="/campaigns/:id"
                      component={CampaignPage}
                    />
                    <Route
                      exact
                      path="/error/:platform"
                      component={ConnectAccountErrorPage}
                    />
                    <Route exact path="/login" component={LoginPage} />
                    <Route
                      exact
                      path="/transactions"
                      component={TransactionsPage}
                    />
                    <Route component={NotFoundPage} />
                  </Switch>
                  <Footer
                    style={{
                      background: appTheme.palette.background.paper,
                    }}
                    textColor="dark"
                    size="md"
                    bgImage={bgImage}
                    bgImageOpacity={bgImageOpacity}
                    copyright={`© ${new Date().getFullYear()} ${company}`}
                    logo={logoUrl}
                    discordlink={discordLink}
                    twitterlink={twitterLink}
                  />
                </MetamaskProvider>
              </Router>
            </ThemeProvider>
          </QueryClientProvider>
        </Web3ReactProvider>
      </ToastProvider>
    </IntlProvider>
  );
}

export default App;
