import React from "react";

import AccountSection from "../components/AccountSection";

function WalletPage(props) {
  return (
    <AccountSection
      textColor="white"
      variant="dark"
      title="Wallet"
      subtitle=""
    />
  );
}

export default WalletPage;
