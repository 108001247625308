import React from "react";
import { Button } from "react-bootstrap";

function BuyAlphieNoteComponent() {
  const OPENSEA_LINK = 'https://opensea.io/collection/whaletogether';
  return (
    <div className="text-center">
      <h6>NOTE: You need to hold at least one alphie in order to join any campaign.
        {' '}
        <a
          href={OPENSEA_LINK}
          target="_blank"
          rel="noreferrer"
        >
          <Button
            size="sm"
          >
            Buy Now on OpenSea
          </Button>
        </a>
      </h6>
    </div>
  );
}

export default BuyAlphieNoteComponent;