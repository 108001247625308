import React from "react";
import { CgWebsite } from 'react-icons/cg';
import { FaTwitterSquare } from 'react-icons/fa';
import { BsDiscord } from 'react-icons/bs';

import "./SectionHeader.scss";

function CampaignHeader(props) {
  // Render nothing if no title or subtitle
  if (!props.title && !props.subtitle) {
    return null;
  }

  return (
    <header
      className={
        "SectionHeader" + (props.className ? ` ${props.className}` : "")
      }
    >
      {props.title && (
        <h1
          className={
            "font-weight-bold" +
            (props.subtitle && props.spaced ? " mb-4" : "") +
            (!props.subtitle ? " mb-0" : "") +
            (props.size ? ` h${props.size}` : "")
          }
        >
          {props.title}
        </h1>
      )}

      {props.subtitle && (
        <>
          <p className="SectionHeader__subtitle mb-0">{props.subtitle}</p>
          <div style={{ marginTop: '5px' }}>
            {props.websiteUrl && <a href={props.websiteUrl} style={{ color: 'white'}} target="_blank" rel="noreferrer">
              <CgWebsite size={31} style={{ marginRight: '5px' }} />
            </a>}
            {props.twitterUrl && <a href={props.twitterUrl} style={{ color: 'white'}} target="_blank" rel="noreferrer">
              <FaTwitterSquare size={27} style={{ marginRight: '5px' }} />
            </a>}
            {props.discordUrl && <a href={props.discordUrl} style={{ color: 'white'}} target="_blank" rel="noreferrer">
              <BsDiscord size={25} />
            </a>}
          </div>
          
          {props.mintPrice && props.mintCurrency && props.totalSupply ? (
            <div style={{ marginTop: '5px' }}>
              <p style={{ marginBottom: '0px' }}>Mint Price: {props.mintPrice} {props.mintCurrency}</p>
              <p style={{ marginBottom: '0px' }}>Total Supply: {props.totalSupply}</p>
            </div>
          ) : null}

        </>
      )}
    </header>
  );
}

export default CampaignHeader;
