import React from "react";
import Modal from '@mui/material/Modal';
import Backdrop from '@mui/material/Backdrop';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useMediaQuery } from 'react-responsive';

import useActiveWeb3React from '../../hooks/useActiveWeb3React';
import useAuth from '../../hooks/useAuth';
import { connectorsConfig, ConnectorNames, CONNECTOR_LOCAL_STORAGE_KEY, WALLET_LOCAL_STORAGE_KEY } from '../../config/constant';
import ShopContext from "../../providers/ShopContext";

function Connector({ onClose, connector }) {
  

  const { account, active, error } = useActiveWeb3React();
  const { login, logout } = useAuth();
  const { icon: Icon, title, connectorId } = connector;

  const onConnectWallet = async (connector) => {
    const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

    if (account) {
      await logout(account);
    }
    // Since iOS does not support Trust Wallet we fall back to WalletConnect
    if (title === "Trust Wallet" && isIOS) {
      await login(ConnectorNames.WalletConnect);
    } else {
      await login(connectorId);
    }

    localStorage.setItem(WALLET_LOCAL_STORAGE_KEY, title);
    localStorage.setItem(CONNECTOR_LOCAL_STORAGE_KEY, connectorId);
    onClose();
  }

  return (
    <Col
      md={12}
      onClick={() => { onConnectWallet(connector) }}
      className="text-center"
      style={{
        padding: '15px 0px',
        cursor: 'pointer',
        border: '1px solid #ffecf3',
        borderRadius: '0 0px 12px 12px',
      }}>
      <Icon width="60px" />
      <h3>{title}</h3>
    </Col>
  )
}

function WalletModal({ open, onClose }) {
  const shopcontext = React.useContext(ShopContext);
  const { primaryColor, secondaryColor } = shopcontext;
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: isMobile ? '80%' : 400,
    backgroundColor: 'white',
    // border: '4px solid #a616a4',
    boxShadow: '5px 5px 15px 5px rgba(0,0,0,0.15)',
    borderRadius: '12px',
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      style={{
        borderRadius: '12px',
      }}
    >
      <Container style={style}>
        <Row>
          <Col
            xs={12}
            className="text-center"
            style={{ 
              padding: '30px',
              borderRadius: '8px 8px 0px 0px',
              background: `linear-gradient(63deg, ${primaryColor} 0%, ${secondaryColor} 100%)`,
            }}>
            <h2 style={{ color: 'white' }}>
              <u>
                Connect Wallet
              </u>
            </h2>
          </Col>
          {connectorsConfig.map(connector => (
            <Connector key={connector.title} connector={connector} onClose={onClose} />
          ))}
        </Row>
      </Container>
    </Modal>
  );
}

export default WalletModal;
