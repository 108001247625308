import React, { useContext, useEffect, useState } from "react";
import { Button, Dropdown } from "react-bootstrap";
import {
  TableCell,
  TableRow,
  Chip,
  Stack,
  Typography,
  Skeleton,
  Avatar,
  Snackbar,
  Tooltip,
} from '@mui/material';
import { Link } from "../../util/router.js";
import { dropTypesLabel, dropTypesBgColor, dropTypes } from "../../enums/dropTypes";
import MoonIcon from "../Shared/MoonIcon";
import JoinNowModal from '../JoinNowModal';
import { Delete, ArrowDownward, CopyAllOutlined, MoreHoriz, EmojiEventsTwoTone } from '@mui/icons-material';
import logoWhite from "../../images/AlphieMoon PFP-01.jpg";

import useActiveWeb3React from '../../hooks/useActiveWeb3React';
import useAuth from "../../hooks/useAuth";
import { getCampaign } from '../../api';
import CircularProgressWithLabel from "../Shared/CircularProgressWithLabel.js";
import { 
  calculateDutchMetaData, 
  calculateRaffleMetaData, 
  getEntriesByMoonAccount, 
  getLiveStatus, 
  getProbablityOfWinning, 
  getStartsIn, 
  getTotalEntries, 
  statusColor, 
  statusText 
} from "../../models/campaign.js";
import { campaignStatuses } from "../../enums/campaignStatuses.js";
import { MoonAccountContext } from "../../providers/MoonAccountProvider.js";
import { accountTypes } from "../../enums/accountTypes.js";
import { deleteCampaign } from "../../api/campaign.js";
import { DateTime } from "luxon";
import ShopContext from "../../providers/ShopContext";

function CampaignRow({ campaign }) {
  const shopcontext = React.useContext(ShopContext);
  const {
    primaryColor,
    secondaryColor,
  } = shopcontext;
  
  const { account } = useActiveWeb3React();
  const { logout } = useAuth();
  const [ modalState, setModalState ] = useState(false);
  const [ thisCampaign, setThisCampaign ] = useState();
  const [ dutchMeta, setDutchMeta ] = useState(0);
  const [ raffleMeta, setRaffleMeta ] = useState(0);
  const [ totalEntries, setTotalEntries ] = useState(0);
  const [ userEntries, setUserEntries ] = useState(0);
  const [ winningPercentage, setWinningPercentage ] = useState(0);
  const [ loading, setLoading ] = useState(false);
  const [ campaignStatus, setCampaignStatus ] = useState(campaignStatuses.scheduled);
  const moonAccount = useContext(MoonAccountContext);
  const [ snackbarShow, setSackbarShow ] = useState(false);
  const [ deleted, setDeleted ] = useState(false);
  const [ snackbarMessage, setSnackbarMessage ] = useState(0);
  const [ startsIn, setStartsIn ] = useState(false);

  const toggleModal = () => {
    setModalState(!modalState);
  }

  const onModalClose = async () => {
    toggleModal();
    await reloadCampaing();
  }

  const reloadCampaing = async () => {
    setLoading(true);
    try {
      const { campaign: updatedCampaign } = await getCampaign(account, campaign._id);
      setThisCampaign(updatedCampaign);
      setLoading(false);
    } catch (error) {
      logout(account);
      setLoading(false);
      console.log('error:', error);
    }
  }

  const closeSnackBar = () => {
    setSackbarShow(false);
  }

  const confirmDeleteCampaign = async () => {
    const { projectName } = thisCampaign;
    if (window.confirm(`Are you sure you want to delete campaign "${projectName}"`)) {
      try {
        await deleteCampaign(account, thisCampaign._id);
        setThisCampaign(undefined);
        setSnackbarMessage(`"${projectName}" campaign was deleted.`)
        setSackbarShow(true);
        setDeleted(true);
      } catch (error) {
        setSnackbarMessage(`Error deleting "${projectName}"`)
        setSackbarShow(true);
      }
    }
  }

  useEffect(() => {
    let intervalTimer;
    let startTimer;
    if (thisCampaign) {
      setTotalEntries(getTotalEntries(thisCampaign));
      setUserEntries(getEntriesByMoonAccount(thisCampaign, moonAccount));
      if (thisCampaign.dropType === dropTypes.raffle) {
        const percentage = getProbablityOfWinning(thisCampaign, moonAccount) * 100
        setWinningPercentage(percentage >= 100 ? percentage.toFixed(0) : percentage >= 10 ? percentage.toFixed(1) : percentage.toFixed(2))
      }
      switch (thisCampaign.dropType) {
        case dropTypes.dutch: 
          setCampaignStatus(getLiveStatus(thisCampaign));
          intervalTimer = setInterval(() => {
            setDutchMeta(calculateDutchMetaData(thisCampaign)); 
            const liveStatus = getLiveStatus(thisCampaign)
            setCampaignStatus(liveStatus);
            if (liveStatus === campaignStatuses.completed) {
              if (intervalTimer) clearInterval(intervalTimer);
            }
          }, 1000);
          break;
        case dropTypes.raffle:
          setCampaignStatus(getLiveStatus(thisCampaign));
          intervalTimer = setInterval(() => {
            setRaffleMeta(calculateRaffleMetaData(thisCampaign));
            const liveStatus = getLiveStatus(thisCampaign)
            setCampaignStatus(liveStatus);
            if (liveStatus === campaignStatuses.completed) {
              if (intervalTimer) clearInterval(intervalTimer);
            }
          }, 1000);
          break;
        default:
          setCampaignStatus(getLiveStatus(thisCampaign));
      }
      if (getLiveStatus(thisCampaign) === campaignStatuses.scheduled) {
        startTimer = setInterval(() => {
          setStartsIn(getStartsIn(thisCampaign))
          const liveStatus = getLiveStatus(thisCampaign)
          setCampaignStatus(liveStatus);
          if (liveStatus !== campaignStatuses.scheduled) {
            reloadCampaing();
            if (startTimer) clearInterval(startTimer);
          }
        }, 1000);
      }
    }
    return() => {
      if (intervalTimer) clearInterval(intervalTimer);
      if (startTimer) clearInterval(startTimer);
    }
  }, [thisCampaign, moonAccount]);

  useEffect(() => {
    if (campaign) {
      setThisCampaign(campaign)
    }
  }, [campaign]);

  return (
    <>
      {thisCampaign && !loading ? (
        <>
          <JoinNowModal open={modalState} onClose={onModalClose} campaign={thisCampaign} account={account} currentPrice={thisCampaign.dropType === dropTypes.dutch ? dutchMeta.currentPrice : null} remainingText={thisCampaign.dropType === dropTypes.dutch ? dutchMeta.intervalRemaining : null} />
          <TableRow
            key={thisCampaign._id}
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
          >
            <TableCell>
              {thisCampaign.thumbnailImageUrl ? (<Avatar variant="rounded" src={thisCampaign.thumbnailImageUrl} />) : thisCampaign.imageUrl ? (<Avatar variant="rounded" src={thisCampaign.imageUrl} />) : (<Avatar variant="rounded" src={logoWhite} />)}
            </TableCell>
            <TableCell>
              <Link to={`/campaigns/${thisCampaign._id}`} className="h6" >{thisCampaign.projectName}</Link>
            </TableCell>
            <TableCell align="center">
              <Chip label={dropTypesLabel[thisCampaign.dropType]} style={{ backgroundColor:dropTypesBgColor[thisCampaign.dropType], color: 'white' }} size="small" />
            </TableCell>
            <TableCell align="center">
              <Chip label={statusText[campaignStatus] ?? 'N/A'} color={statusColor[campaignStatus] ?? 'default'} size="small" />
            </TableCell>
            <TableCell align="center">
              { thisCampaign.wlAvailable }
            </TableCell>
            <TableCell align="center">
              {(() => {
                switch(thisCampaign.dropType) {
                  case dropTypes.dutch:
                    return (<>
                        {campaignStatus === campaignStatuses.active ? dutchMeta.currentPrice : '—'}
                        <MoonIcon size={20} />
                        </>);
                  default: 
                    return (<>
                      {(thisCampaign.pricePerEntry !== 0) ? thisCampaign.pricePerEntry : 'FREE'}
                      {(thisCampaign.pricePerEntry !== 0) ? <MoonIcon size={20} /> : null }
                      </>);
                }
              })()}
            </TableCell>
            <TableCell align="center">
              <Stack>
              <div><b>{ userEntries }</b> / { totalEntries }</div>
              {thisCampaign.dropType === dropTypes.raffle && <small className="text-muted">{ winningPercentage }% <EmojiEventsTwoTone style={{ fontSize: 14 }}/></small>}
              </Stack>
            </TableCell>
            <TableCell align="center">
              {campaignStatus === campaignStatuses.active ? 
                (() => {
                  switch(thisCampaign.dropType) {
                    case dropTypes.dutch:
                      return (dutchMeta.currentPrice > thisCampaign.endingPrice &&
                          <Stack alignItems="center" justifyContent="center" spacing={0}>
                            <CircularProgressWithLabel size={30} thickness={4} variant="determinate" value={dutchMeta.intervalRemainingPercentage ?? 0} label={dutchMeta.intervalRemaining ?? ''}  />
                            <Typography variant="counterCaption" component="div" mt={0.5}><Stack direction='row'>price <ArrowDownward style={{ fontSize: 14 }}/></Stack></Typography>
                          </Stack>);
                    case dropTypes.raffle:
                      return (
                        <Tooltip title={ DateTime.fromISO(thisCampaign.startTime).plus(thisCampaign.durationMs).toLocaleString(DateTime.DATETIME_SHORT)} arrow>
                          <Stack alignItems="center" justifyContent="center" spacing={0}>
                            <CircularProgressWithLabel size={30} thickness={4} variant="determinate" value={raffleMeta.remainingPercentage ?? 0} label={raffleMeta.remaining ?? ''}  />
                            <Typography variant="counterCaption" component="div" mt={0.5}>to end</Typography>
                          </Stack>
                        </Tooltip>);
                    default: 
                      return null;
                  }
                })()
              : campaignStatus === campaignStatuses.scheduled ? 
              <Tooltip title={ DateTime.fromISO(thisCampaign.startTime).toLocaleString(DateTime.DATETIME_SHORT)} arrow>
                <Stack alignItems="center" justifyContent="center" spacing={0}>
                  <CircularProgressWithLabel color="secondary" size={30} thickness={4} variant="determinate" value={startsIn.percentage ?? 100} label={startsIn.label ?? ''}  />
                  <Typography variant="counterCaption" component="div" mt={0.5}>to start</Typography>
                </Stack>
              </Tooltip>
              : null}
            </TableCell>
            <TableCell align="right">
              <Stack direction='row' justifyContent='end' spacing={1}>
              {campaignStatus === campaignStatuses.active ? (
                <Button
                  size="sm"
                  block 
                  style={{
                    background: `linear-gradient(63deg, ${primaryColor} 0%, ${secondaryColor} 100%)`,
                    border: 'transparent',
                  }}
                  onClick={toggleModal}
                >
                  Join Now
                </Button>
              ) : (campaignStatus === campaignStatuses.completed &&
                <Link to={`/campaigns/${thisCampaign._id}`} className="btn btn-primary btn-sm btn-block"
                role="button">
                    View Result
                </Link>
              )}
              { moonAccount?.account?.type === accountTypes.admin && <Dropdown>
                <Dropdown.Toggle variant="light" id="dropdown-basic" size="sm">
                  <MoreHoriz fontSize="small" />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Link to={{ pathname: '/campaigns/new', state: { templateCampaign: thisCampaign } }} className="dropdown-item">
                    <CopyAllOutlined fontSize="small"/> New From This Campaign
                  </Link>
                  <Dropdown.Item onClick={confirmDeleteCampaign}><Delete fontSize="small"/> Delete</Dropdown.Item>
                </Dropdown.Menu>
                </Dropdown>
              }
              </Stack>
            </TableCell>
          </TableRow>
        </>
      ) : !deleted && <TableRow key={'loading'} sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
        <TableCell>
        </TableCell>
        <TableCell>
          <Skeleton variant="text"/>
        </TableCell>
        <TableCell align="center">
          <Skeleton variant="text"/>
        </TableCell>
        <TableCell align="center">
          <Skeleton variant="text"/>
        </TableCell>
        <TableCell align="center">
          <Skeleton variant="text"/>
        </TableCell>
        <TableCell align="center">
          <Skeleton variant="text"/>
        </TableCell>
        <TableCell align="center">
          <Skeleton variant="text"/>
        </TableCell>
        <TableCell align="center">
        </TableCell>
        <TableCell align="right">
        </TableCell>
      </TableRow>}
      <Snackbar
        open={snackbarShow}
        onClose={closeSnackBar}
        autoHideDuration={6000}
        message={snackbarMessage}
      />
    </>
  );
}

export default CampaignRow;