import { Contract } from '@ethersproject/contracts';

import DEV_WALLET_ABI from "../config/abi/dev-wallet.json";
import NFT_ABI from "../config/abi/nft.json";
import ERC20_ABI from "../config/abi/erc20.json";
import MOONTOKEN_ABI from "../config/abi/moontoken.json";


export const getErc721Contract = (address, signer) => {
  return new Contract(address, NFT_ABI, signer)
}

export const getErc20Contract = (address, signer) => {
  return new Contract(address, ERC20_ABI, signer)
}

export const getMoonTokenContract = (address, signer) => {
  return new Contract(address, MOONTOKEN_ABI, signer)
}

export const getDevWalletContract = (address, signer) => {
  return new Contract(address, DEV_WALLET_ABI, signer)
}

export const getNFTContract = (address, signer) => {
  return new Contract(address, NFT_ABI, signer)
}