import { ALPHIEMOON_BE_BASE_URL, WEB3_TOKEN_KEY } from '../config/constant';

export const getCampaigns = async (address, page, limit) => {
  if (!address) return false;

  const web3Token = localStorage.getItem(`${WEB3_TOKEN_KEY}-${address}`);

  const url = `${ALPHIEMOON_BE_BASE_URL}/api/campaigns?page=${page}limit=${limit}`;

  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${web3Token}`
    },
  };
  const res = await fetch(url, requestOptions);
  if (res.ok) return res.json();
  throw Error(res.statusText);
}

export const getCampaign = async (address, id) => {
  if (!address) return false;

  const web3Token = localStorage.getItem(`${WEB3_TOKEN_KEY}-${address}`);

  const url = `${ALPHIEMOON_BE_BASE_URL}/api/campaigns/${id}`;

  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${web3Token}`
    },
  };
  const res = await fetch(url, requestOptions);
  if (res.ok) return res.json();
  throw Error(res.statusText);
}

export const joinCampaign = async (address, id, userBoughtEntries) => {
  if (!address) return false;

  const web3Token = localStorage.getItem(`${WEB3_TOKEN_KEY}-${address}`);

  const url = `${ALPHIEMOON_BE_BASE_URL}/api/campaigns/${id}`;

  const requestOptions = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${web3Token}`
    },
    body: JSON.stringify({ userBoughtEntries }),
  };
  const res = await fetch(url, requestOptions);
  if (res.ok) return res;

  const error = await res.json();
  throw Error(error.message);
}

export const createCampaign = async (address, payload) => {
  if (!address) return false;

  const web3Token = localStorage.getItem(`${WEB3_TOKEN_KEY}-${address}`);

  const url = `${ALPHIEMOON_BE_BASE_URL}/api/campaigns/`;

  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${web3Token}`
    },
    body: JSON.stringify(payload),
  };
  const res = await fetch(url, requestOptions);
  if (res.ok) return res;

  const error = await res.json();
  throw Error(error.message);
}

export const deleteCampaign = async (address, id) => {
  if (!address) return false;

  const web3Token = localStorage.getItem(`${WEB3_TOKEN_KEY}-${address}`);

  const url = `${ALPHIEMOON_BE_BASE_URL}/api/campaigns/${id}`;

  const requestOptions = {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${web3Token}`
    },
  };
  const res = await fetch(url, requestOptions);
  if (res.ok) return res;

  const error = await res.json();
  throw Error(error.message);
}