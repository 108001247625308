import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { useMediaQuery } from "react-responsive";
import styled from "@emotion/styled";
import { keyframes } from "@emotion/react";
import pulse from "react-animations/lib/pulse";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

import Section from "./Section";

import WalletModal from "../WalletModal";

import useActiveWeb3React from "../../hooks/useActiveWeb3React";

import shopBG from "../../images/moon-shop-bg.jpg";
import logoBig from "../../images/shop-logo-big.png";
import ShopContext from "../../providers/ShopContext";

const pulseAnimation = keyframes`${pulse}`;

const PulseButton = styled(Button)`
  animation: 3s ${pulseAnimation};
  animation-iteration-count: infinite;
`;

function LoginSection(props) {
  const shopcontext = React.useContext(ShopContext);
  const {
    textColor,
    title,
    company,
    logoUrl,
    bgImage,
    bgImageOpacity,
    primaryColor,
    secondaryColor,
    twitterLink,
    discordLink,
    loginBgUrl,
    loginLogoUrl
  } = shopcontext;

  const isMobile = useMediaQuery({ maxWidth: 991 });
  const isLarge = useMediaQuery({ minWidth: 1400 });
  const { error } = useActiveWeb3React();

  const [modalState, setModalState] = useState(false);
  const [logginIn, setLogginIn] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const toggleModal = () => {
    setModalState(!modalState);
    setLogginIn(!logginIn);
  };

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 500);
  }, []);

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <WalletModal open={modalState} onClose={toggleModal} />
      <Section
        bg="white"
        textColor="white"
        size={props.size}
        bgImage={loginBgUrl}
        style={{
          paddingBottom: "15%",
        }}
      >
        <Container fluid>
          <Row className="align-items-center">
            <Col
              xs={12}
              className="text-center text-black"
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignContent: "space-between",
                overflow: isMobile ? "hidden" : "inherit",
              }}
            >
              <div
                style={{
                  marginTop: isLarge ? "190px" : "190px",
                  height: isMobile ? "auto" : "52vh",
                }}
              >
                <img
                  className="d-inline-block align-center"
                  src={loginLogoUrl}
                  alt="Logo"
                  height={isMobile ? "257" : "450"}
                  style={{
                    borderRadius: "50%",
                    marginBottom: "10px",
                    display: "inline-block",
                  }}
                />
                <br />
                <PulseButton
                  variant={props.buttonColor}
                  onClick={toggleModal}
                  size="lg"
                  style={{
                    background: "white",
                    color: "black",
                    fontWeight: "600",
                  }}
                  disabled={logginIn}
                >
                  Connect Wallet
                </PulseButton>
                {error && error.name === "UnsupportedChainIdError" && (
                  <h6 style={{ marginTop: "10px", color: "red" }}>
                    <u>Please change to Mainnet!</u>
                  </h6>
                )}
              </div>
            </Col>
          </Row>
        </Container>
      </Section>
    </>
  );
}

export default LoginSection;
