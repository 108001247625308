import React, { useEffect, useState } from "react";
import { useLocation } from 'react-router-dom'
import { Container, Row, Col } from "react-bootstrap";
import { 
  Card, 
  CardContent, 
  MenuItem, 
  TextField, 
  CircularProgress, 
  Switch, 
  FormGroup, 
  FormControlLabel, 
  ToggleButtonGroup, 
  ToggleButton,
  Button,
  Divider,
  InputLabel,
} from "@mui/material";
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import SectionHeader from "../SectionHeader";
import { useToasts } from 'react-toast-notifications';
import Backdrop from '@mui/material/Backdrop';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { DateTime } from 'luxon';

import { getAccount, createCampaign } from '../../api';
import useActiveWeb3React from '../../hooks/useActiveWeb3React';
import useAuth from "../../hooks/useAuth";
import { accountTypes } from "../../enums/accountTypes";
import * as Campaign from "../../models/campaign";

function CreateCampaignComponent() {
  const form = Campaign.form(DateTime.local()); // Start form with current time
  const validationSchema = Yup.object().shape(form.makeValidationSchema(form.formInputs));
  const { active, account } = useActiveWeb3React();
  const [ moonAccount, setMoonAccount ] = useState();
  const [ isLoading, setIsLoading ] = useState(true);
  const { logout } = useAuth();
  const { addToast } = useToasts();
  const location = useLocation();

  useEffect(() => {
    const init = async () => {
      try {
        await getMoonAccount();
        setIsLoading(false);
      } catch (e) {
        logout(account);
      }
    }

    const getMoonAccount = async () => {
      try {
        const moonAccount = await getAccount(account);
        setMoonAccount(moonAccount);
      } catch (error) {
        console.log('error:', error);
      }
    }

    if (account) {
      init();
    }
  }, [account, logout]);  

  const templateCampaignValues = {...location.state?.templateCampaign ?? {} };
  delete templateCampaignValues._id;
  delete templateCampaignValues.startTime;
  delete templateCampaignValues.mintDate;
  delete templateCampaignValues.mintTime;
  delete templateCampaignValues.entries;
  delete templateCampaignValues.winners;
  if (templateCampaignValues.decreaseInterval) {
    templateCampaignValues.decreaseInterval = Math.round(templateCampaignValues.decreaseInterval / 60000)
  }

  const formik = useFormik({
    
    initialValues: { ...form.initialValues, ...templateCampaignValues },
    validationSchema: validationSchema,
    onSubmit: async (values, {setSubmitting, resetForm}) => {
      const preparedValue = { ...values };
      if (preparedValue.decreaseInterval) {
        preparedValue.decreaseInterval = preparedValue.decreaseInterval * 60000 // Convert Minute to Milliseconds
      }
      
      form.formInputs.forEach((input) => {
        if (preparedValue[input.name]) {
          switch (input.type) {
            case 'datetime': 
              preparedValue[input.name] = preparedValue[input.name]?.toUTC().toFormat("yyyy-MM-dd'T'T':00.000Z'") ?? null;
              break;
            case 'date': 
              preparedValue[input.name] = preparedValue[input.name]?.toISODate() ?? null;
              break;
            case 'time': 
              preparedValue[input.name] = preparedValue[input.name]?.toFormat("T':00.000Z'") ?? null; 
              break;
            case 'number' :
              preparedValue[input.name] = Number(preparedValue[input.name]);
              break;
            default:
          }
        }
      });

      // alert(JSON.stringify(preparedValue, null, 2))
      setSubmitting(true);
      await createCampaign(account, preparedValue);

      resetForm();
      setSubmitting(false);

      const message = 'New campaign was created successfully!';
      addToast(message, {
        appearance: 'info',
        autoDismiss: true,
      });
    },
  });
  
  return (
    <>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Container>
        <SectionHeader
          className="header-font above-fold-header text-center"
          title="New Campaign"
          size={1}
        />
        {!isLoading && active && moonAccount && moonAccount.account.type === accountTypes.admin ? (
          <Card>
            <CardContent>
              <form onSubmit={formik.handleSubmit} noValidate>
                <Row>
                  {form.formInputs.map((formInput, idx) => formInput.type === 'divider' ? (
                    <Col key={`col_${idx}`} xs={12}><Divider className="my-4" /></Col>
                  ) : (
                    <Col 
                      key={`col_${idx}`}
                      xs={formInput.xs ?? 12} 
                      sm={formInput.sm ?? formInput.xs ?? 12} 
                      md={formInput.md ?? formInput.sm ?? formInput.md ?? 12} 
                      lg={formInput.md ?? formInput.sm ?? formInput.md ?? formInput.lg ?? 12}
                      >
                      {(!formInput.dependOn || 
                        (formInput.dependOn && !formInput.dependOnValues && formik.values[formInput.dependOn]) || 
                        (formInput.dependOn && formInput.dependOnValues && formInput.dependOnValues.includes(formik.values[formInput.dependOn]))) ? (
                        <>
                          {(() => {
                            switch(formInput.type) {
                              case 'toggle': 
                                return ( 
                                  <FormGroup key={`FG_${idx}`}>
                                    <InputLabel>{formInput.label}</InputLabel>
                                    <ToggleButtonGroup
                                      id={formInput.name}
                                      key={formInput.name}
                                      name={formInput.name}
                                      required={formInput.required}
                                      disabled={formInput.disabled} 
                                      exclusive
                                      value={formik.values[formInput.name]}
                                      onChange={(e, selected) => formik.setFieldValue(formInput.name, selected)}
                                      >
                                      {formInput.options.map((option, idx) => (
                                        <ToggleButton key={`${formInput.name}_${idx}`} value={option.value}>
                                          {option.label}
                                        </ToggleButton>
                                      ))}
                                    </ToggleButtonGroup>
                                  </FormGroup>
                                );
                              case 'switch': 
                                return ( 
                                  <FormGroup key={`FG_${idx}`}>
                                    <FormControlLabel control={
                                      <Switch 
                                        id={formInput.name}
                                        key={formInput.name}
                                        name={formInput.name}
                                        disabled={formInput.disabled} 
                                        checked={formik.values[formInput.name]}
                                        onChange={event => formik.setFieldValue(formInput.name, event.target.checked)}
                                      />
                                    } 
                                    label={formInput.label} />
                                  </FormGroup>
                                );
                              case 'date':
                                return ( 
                                  <LocalizationProvider dateAdapter={AdapterLuxon}>
                                    <DatePicker
                                      renderInput={(props) => <TextField {...props} key={`${formInput.name}_text`} error={Boolean(formik.errors[formInput.name])} required={formInput.required} helperText={formik.errors[formInput.name] ?? formInput.helperText} />}
                                      id={formInput.name}
                                      key={formInput.name}
                                      name={formInput.name}
                                      label={formInput.label}
                                      minDate={formInput.minDate}
                                      maxDate={formInput.maxDate}
                                      required={formInput.required}
                                      disabled={formInput.disabled}
                                      value={formik.values[formInput.name]}
                                      onChange={value => formik.setFieldValue(formInput.name, value)}
                                    />
                                  </LocalizationProvider>
                                );
                              case 'time':
                                return ( 
                                  <LocalizationProvider dateAdapter={AdapterLuxon}>
                                    <TimePicker
                                      renderInput={(props) => <TextField {...props} key={`${formInput.name}_text`}  error={Boolean(formik.errors[formInput.name])} required={formInput.required} helperText={formik.errors[formInput.name] ?? formInput.helperText} />}
                                      id={formInput.name}
                                      key={formInput.name}
                                      name={formInput.name}
                                      label={formInput.label}
                                      required={formInput.required}
                                      disabled={formInput.disabled}
                                      value={formik.values[formInput.name]}
                                      onChange={value => formik.setFieldValue(formInput.name, value)}
                                      error={Boolean(formik.errors[formInput.name])}
                                    />
                                  </LocalizationProvider>
                                );
                              case 'datetime':
                                return ( 
                                  <LocalizationProvider dateAdapter={AdapterLuxon}>
                                    <DateTimePicker
                                      renderInput={(props) => <TextField {...props} key={`${formInput.name}_text`}  error={Boolean(formik.errors[formInput.name])} required={formInput.required} helperText={formik.errors[formInput.name] ?? formInput.helperText} />}
                                      id={formInput.name}
                                      key={formInput.name}
                                      name={formInput.name}
                                      label={formInput.label}
                                      minDateTime={formInput.minDateTime}
                                      maxDateTime={formInput.maxDateTime}
                                      required={formInput.required}
                                      disabled={formInput.disabled}
                                      value={formik.values[formInput.name]}
                                      onChange={value => formik.setFieldValue(formInput.name, value)}
                                    />
                                  </LocalizationProvider>
                                );
                              default: 
                                return <TextField 
                                        id={formInput.name}
                                        key={formInput.name}
                                        name={formInput.name}
                                        label={formInput.label}
                                        placeholder={formInput.placeholder}
                                        required={formInput.required}
                                        disabled={formInput.disabled}
                                        type={formInput.type}
                                        inputProps={formInput.inputProps}
                                        InputProps={formInput.InputProps} 
                                        select={formInput.type === 'select'}
                                        multiline={formInput.type === 'textarea'}
                                        value={formik.values[formInput.name]}
                                        onChange={event => formik.setFieldValue(formInput.name, event.target.value)}
                                        error={Boolean(formik.errors[formInput.name])}
                                        helperText={formik.errors[formInput.name] ?? formInput.helperText}
                                        >
                                        {formInput.type === 'select' && formInput.options.map(option => (
                                          <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                          </MenuItem>
                                        ))}
                                      </TextField>
                            }
                          })()}
                        </>
                      ) : null}
                    </Col>    
                  ))}
                </Row>
                <Divider className="my-4"></Divider>
                <div className="text-center text-primary py-2 small">{ (!formik.isValid)? "Incomplete / Invalid form input. Please correct all errors and try again.": null }</div>
                <Button 
                  size="large" 
                  variant="contained" 
                  fullWidth
                  type="submit" 
                  className="mb-4"
                  disabled={formik.isSubmitting || !formik.isValid}>
                  Create Campaign
                </Button>
                  
                  
              </form>
            </CardContent>
          </Card>
        ) : (
          <Row>
            <Col xs={12} className="text-center">
              <h1>You're not allowed to be here</h1>
            </Col>
          </Row>
        )}
      </Container>
    </>
  );
}

export default CreateCampaignComponent;