import React from "react";
import moonCoin from "../../images/moon_coin.png";
import ShopContext from "../../providers/ShopContext";

function MoonIcon({ size }) {
  const shopcontext = React.useContext(ShopContext);
  const { tokenLogo, tokenSymbol } = shopcontext;
  return (
    <img
      className="d-inline-block align-center"
      src={tokenLogo}
      alt={`$${tokenSymbol}`}
      height={size ?? 20}
      style={{
        borderRadius: "50%",
        marginBottom: "5px",
        marginLeft: "5px",
        display: "inline-block",
      }}
    />
  );
}

export default MoonIcon;
