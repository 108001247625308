import { 
    Card, 
    CardContent, 
    Grid, 
    Table, 
    TableCell, 
    TableContainer, 
    TableHead, 
    TablePagination, 
    TableRow 
} from "@mui/material"
import Transaction from "./Transaction"
import * as TransactionModel from "../../models/transaction";

function TransactionsList({transactions, page, totalTransactions, transactionsPerPage, gotoPage, changeTransactionsPerPage}) { 
    return (
        <Card>
            <CardContent>
                { totalTransactions > 0 ? 
                <>
                <TableContainer>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                { TransactionModel.TABLE_FIELDS.map((field) => {
                                    return (
                                        <TableCell>{field.label}</TableCell>
                                    );
                                }) }
                            </TableRow>
                        </TableHead>
                        {transactions.map((transaction) => {
                            return <Transaction transaction={transaction} />
                        })}
                    </Table>
                </TableContainer>
                <Grid container justifyContent='center' className="mt-4">
                    <TablePagination 
                        component="div" 
                        page={page}
                        count={totalTransactions}
                        rowsPerPage={transactionsPerPage}
                        onPageChange={(e, page) => gotoPage(page)}
                        onRowsPerPageChange={(e) => {
                            changeTransactionsPerPage(parseInt(e.target.value, 10));
                            gotoPage(1);
                        }}
                    />
                </Grid>
                </>
                : <h4>No transactions</h4> }
            </CardContent>
        </Card>
    )
}


export default TransactionsList