import { async } from "analytics/lib/analytics.cjs";
import {
  ALPHIEMOON_BE_BASE_URL,
  WEB3_TOKEN_KEY,
} from "../config/constant";
export const getShop = async (address) => {
  if (!address) return false;
  const web3Token = localStorage.getItem(`${WEB3_TOKEN_KEY}-${address}`);
  const url = `${ALPHIEMOON_BE_BASE_URL}/api/shop/default`;
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${web3Token}`,
    },
  };
  const res = await fetch(url, requestOptions);
  if (res.ok) return res.json();
  throw Error(res.statusText);
};

export const getThemeShop = async () => {
  const url = `${ALPHIEMOON_BE_BASE_URL}/api/shop/default`;
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  };
  const res = await fetch(url, requestOptions);
  if (res.ok) return res.json();
  throw Error(res.statusText);
};

export const createShop = async (address, payload) => {
  if (!address) return false;
  const web3Token = localStorage.getItem(`${WEB3_TOKEN_KEY}-${address}`);
  const url = `${ALPHIEMOON_BE_BASE_URL}/api/shop`;
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${web3Token}`,
    },
    body: JSON.stringify(payload),
  };
  const res = await fetch(url, requestOptions);
  if (res.ok) return res;

  const error = await res.json();
  throw Error(error.message);
};

export const updateShopTheme = async (address,payload) =>{
  if (!address) return false;
  const web3Token = localStorage.getItem(`${WEB3_TOKEN_KEY}-${address}`);
  const url = `${ALPHIEMOON_BE_BASE_URL}/api/shop/${payload.id}`;
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${web3Token}`,
    },
    body: JSON.stringify(payload),
  };
  const res = await fetch(url, requestOptions);
  if (res.ok) return res;

  const error = await res.json();
  throw Error(error.message);
}
