import React from "react";

import CampaignSection from "../components/CampaignSection";

function CampaignPage(props) {
  return (
    <CampaignSection
      textColor="white"
      variant="dark"
      title="Wallet"
      subtitle=""
    />
  );
}

export default CampaignPage;
