import React, { useContext } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Pagination from '@mui/material/Pagination';
import { MoonAccountContext } from "../../providers/MoonAccountProvider";
import { Chip, Grid, Stack } from "@mui/material";

function EntryListComponent({ entries, winners }) {
  const counts = {};
  entries.forEach((entry) => { 
    counts[entry.id] = (counts[entry.id] || 0) + 1;
  });
  let seen = new Set();
  const uniqueEntries = entries.filter(entry => {
      let k = entry.id;
      return seen.has(k) ? false : seen.add(k);
  });

  const entryListToDisplay = winners || uniqueEntries;
  const moonAccount = useContext(MoonAccountContext);

  return (
    <>
      {entryListToDisplay && (
        <Stack spacing={4}>
          <TableContainer>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="left">
                    <b>NAME</b>
                  </TableCell>
                  {/* <TableCell align="left">
                    <b>ID</b>
                  </TableCell> */}
                  <TableCell align="left">
                    <b>ENTRIES</b>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {entryListToDisplay.map((entry) => (
                  <TableRow
                    key={entry.id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell align="left">
                      <Stack direction='row' spacing={1}>
                      <div>{entry.name || entry.username}</div>
                      { (moonAccount?.account?.discordId === entry.id) && <Chip label="YOUR ENTRIES" color='secondary' size="small" /> }
                      </Stack>
                    </TableCell>
                    {/* <TableCell align="left">{entry.id}</TableCell> */}
                    <TableCell align="left">{counts[entry.id]}</TableCell>                      
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          {/* <Grid container justifyContent={'center'} >
            <Pagination />
          </Grid> */}
        </Stack>
      )}
    </>
  );
}

export default EntryListComponent;