import { ALPHIEMOON_BE_BASE_URL, WEB3_TOKEN_KEY } from "../config/constant";

export {
  getCampaigns,
  getCampaign,
  joinCampaign,
  createCampaign,
} from "./campaign";

export { getShop } from "./shop";

export const register = async (authToken) => {
  if (!authToken) return false;

  try {
    const url = `${ALPHIEMOON_BE_BASE_URL}/api/auth/register`;

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    };
    const res = await fetch(url, requestOptions);
    if (res.ok) {
      return;
    }
    throw Error(res.statusText);
  } catch (error) {
    console.log("error->", error);
  }
};

export const getAccount = async (address) => {
  if (!address) return false;

  const web3Token = localStorage.getItem(`${WEB3_TOKEN_KEY}-${address}`);

  const url = `${ALPHIEMOON_BE_BASE_URL}/api/account/${address}`;

  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${web3Token}`,
    },
  };
  const res = await fetch(url, requestOptions);
  if (res.ok) return res.json();
  throw Error(res.statusText);
};

export const getAccountByDiscordId = async (address, discordId) => {
  if (!discordId) return false;

  const web3Token = localStorage.getItem(`${WEB3_TOKEN_KEY}-${address}`);

  const url = `${ALPHIEMOON_BE_BASE_URL}/api/account/discord/${discordId}`;

  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${web3Token}`,
    },
  };
  const res = await fetch(url, requestOptions);
  if (res.ok) return res.json();
  throw Error(res.statusText);
};

export const getAccountByTwitterId = async (address, twitterId) => {
  if (!twitterId) return false;

  const web3Token = localStorage.getItem(`${WEB3_TOKEN_KEY}-${address}`);

  const url = `${ALPHIEMOON_BE_BASE_URL}/api/account/twitter/${twitterId}`;

  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${web3Token}`,
    },
  };
  const res = await fetch(url, requestOptions);
  if (res.ok) return res.json();
  throw Error(res.statusText);
};

export const getMyWinsCall = async (address, page, limit) => {
  if (!address) return false;

  const web3Token = localStorage.getItem(`${WEB3_TOKEN_KEY}-${address}`);

  const url = `${ALPHIEMOON_BE_BASE_URL}/api/account/self/wins?page=${page}limit=${limit}`;

  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${web3Token}`,
    },
  };
  const res = await fetch(url, requestOptions);
  if (res.ok) return res.json();
  throw Error(res.statusText);
};

export const updateAccount = async (address, payload) => {
  if (!payload) return false;

  const web3Token = localStorage.getItem(`${WEB3_TOKEN_KEY}-${address}`);

  const url = `${ALPHIEMOON_BE_BASE_URL}/api/account/self`;

  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${web3Token}`,
    },
    body: JSON.stringify(payload),
  };
  const res = await fetch(url, requestOptions);
  if (res.ok) return true;
  throw Error(res.statusText);
};

export const refreshTokenSelf = async (address, refreshToken) => {
  if (!refreshToken) return false;

  const web3Token = localStorage.getItem(`${WEB3_TOKEN_KEY}-${address}`);

  try {
    const url = `${ALPHIEMOON_BE_BASE_URL}/api/account/discord/self`;

    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${web3Token}`,
      },
      body: JSON.stringify({ refreshToken }),
    };
    const res = await fetch(url, requestOptions);
    if (res.ok) return true;
    throw Error(res.statusText);
  } catch (error) {
    console.log("error->", error);
  }
};

export const revokeTokenSelf = async (address, accessToken) => {
  if (!accessToken) return false;

  const web3Token = localStorage.getItem(`${WEB3_TOKEN_KEY}-${address}`);

  try {
    const url = `${ALPHIEMOON_BE_BASE_URL}/api/account/discord/self`;

    const requestOptions = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${web3Token}`,
      },
      body: JSON.stringify({ accessToken }),
    };
    const res = await fetch(url, requestOptions);
    if (res.ok) return true;
    throw Error(res.statusText);
  } catch (error) {
    console.log("error->", error);
  }
};
