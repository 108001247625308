import Metamask from "../components/Svg/Icons/Metamask";
import WalletConnect from "../components/Svg/Icons/WalletConnect";
import TrustWallet from "../components/Svg/Icons/TrustWallet";
import Coin98 from "../components/Svg/Icons/Coin98";

export const POLLING_INTERVAL = 12000;
export const ConnectorNames = {
  Injected: "injected",
  WalletConnect: "walletconnect",
};

export const connectorsConfig = [
  {
    title: "Metamask",
    icon: Metamask,
    connectorId: ConnectorNames.Injected,
    priority: 1,
  },
  // {
  //   title: "WalletConnect",
  //   icon: WalletConnect,
  //   connectorId: ConnectorNames.WalletConnect,
  //   priority: 2,
  // },
  // {
  //   title: "Trust Wallet",
  //   icon: TrustWallet,
  //   connectorId: ConnectorNames.Injected,
  //   priority: 3,
  // },
  // {
  //   title: "Coin98",
  //   icon: Coin98,
  //   connectorId: ConnectorNames.Injected,
  //   priority: 999,
  // },
];
export const SERVER_ID = process.env.REACT_APP_GUILD_ID;
export const MAX_ENTRIES = 25;
export const NFT_CONTRACT_ADDRESS = process.env.REACT_APP_NFT_CONTRACT_ADDRESS;
export const CONNECTOR_LOCAL_STORAGE_KEY = "connectorId";
export const WALLET_LOCAL_STORAGE_KEY = "wallet";
export const WEB3_TOKEN_KEY = "web3Token";
export const ALPHIEMOON_BE_BASE_URL =
  process.env.REACT_APP_ALPHIEMOON_BE_BASE_URL;
export const DISCORD_CLIENT_ID = process.env.REACT_APP_DISCORD_CLIENT_ID;
export const DISCORD_REDIRECT_URI = `${ALPHIEMOON_BE_BASE_URL}/api/auth/web/discord/callback`;
export const DISCORD_LOGIN_URL = `https://discord.com/api/oauth2/authorize?client_id=${DISCORD_CLIENT_ID}&redirect_uri=${DISCORD_REDIRECT_URI}&response_type=code&scope=guilds%20guilds.members.read%20identify`; //guilds%20identify%20guilds.join
