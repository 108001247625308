import React from "react";

import ConnectAccountErrorSection from "../components/ConnectAccountErrorSection";

function ConnectAccountErrorPage() {
  return (
    <ConnectAccountErrorSection
      textColor="white"
      variant="dark"
      title=""
      subtitle=""
    />
  );
}

export default ConnectAccountErrorPage;
