import React from "react";

import CreateCampaignSection from "../components/CreateCampaignSection";

function CreateCampaignPage(props) {
  return (
    <CreateCampaignSection
      textColor="white"
      variant="dark"
      title="New Campaign"
      subtitle=""
    />
  );
}

export default CreateCampaignPage;
