import React, { useEffect, useState } from "react";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import SectionHeader from "../SectionHeader";
import CampaignListComponent from '../CampaignListComponent';
import BuyAlphieNoteComponent from '../BuyAlphieNoteComponent';

import { getCampaigns } from '../../api';
import useActiveWeb3React from '../../hooks/useActiveWeb3React';
import { useNFT } from '../../hooks/useContract';
import useAuth from "../../hooks/useAuth";
import { Card, CardContent, Stack } from "@mui/material";

const NFT_CONTRACT_ADDRESS = process.env.REACT_APP_NFT_CONTRACT_ADDRESS;

function CampaignsComponent() {
  const { active, account } = useActiveWeb3React();
  const [isLoading, setIsLoading] = useState(true);
  const [campaigns, setCampaigns] = useState([]);
  const [nftContract] = useState(useNFT(NFT_CONTRACT_ADDRESS));
  const [totalPages, setTotalPages] = useState(0);
  const [totalAlphiesHold, setTotalAlphiesHold] = useState(0);
  const { logout } = useAuth();

  useEffect(() => {
    if (nftContract && active && account) {
      init();
      getAlphiesByAccount();
    }
  }, [active, account]);

  const init = async () => {
    await getMoonCampaigns();
    setIsLoading(false);
  }

  const getAlphiesByAccount = async () => {
    const tokenIds = await nftContract.walletOfOwner(account);
    setTotalAlphiesHold(tokenIds.length);
  }

  const getMoonCampaigns = async (page = 1, limit = 10) => {
    try {
      const { campaigns } = await getCampaigns(account, page, limit);
      setTotalPages(campaigns.totalPages);
      setCampaigns(campaigns.docs);
    } catch (error) {
      logout(account);
      console.log('error:', error);
    }
  }

  const onChangePage = async (e, value) => {
    const page = value;
    await getMoonCampaigns(page);
  }

  return (
    <>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {!isLoading && account && (
        <>
          <SectionHeader
            className="header-font above-fold-header text-center"
            title="Campaigns"
            size={1}
          />
          <Stack spacing={4}>
            <Card>
              <CardContent>
                <CampaignListComponent
                  campaigns={campaigns}
                  totalPages={totalPages}
                  onChangePage={onChangePage}
                />
              </CardContent>
            </Card>
            {totalAlphiesHold <= 0 && (
              <BuyAlphieNoteComponent />
            )}
          </Stack>
        </>
      )}
    </>
  );
}

export default CampaignsComponent;