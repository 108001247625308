import cryingAlphieImage from "../../images/crying-alphie.png";

function CryingAlphie({ size, style, className }) {
    return (
        <img
            className={className}
            src={cryingAlphieImage}
            alt="Sad Alphie"
            height={size ?? 20}
            style={style}
        />
    );
}

export default CryingAlphie;