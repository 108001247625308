import { Card, CardContent } from "@mui/material";
import React, { useState } from "react";
import { Container, Form, Row, Col, Button } from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";
import { useMediaQuery } from "react-responsive";
import { useToasts } from "react-toast-notifications";

import { updateAccount } from "../../api";

function WLWalletComponent({ moonAccount }) {
  const [wlWallet, setWlWallet] = useState(moonAccount.account.wlWallet);
  const [btcOrdinalWallet, setBtcOrdinalWallet] = useState(
    moonAccount.account.btcOrdinalWallet,
  );
  const [isUpdatingEth, setIsUpdatingEth] = useState(false);
  const [isUpdatingBtcOrdinal, setIsUpdatingBtcOrdinal] = useState(false);

  const { addToast } = useToasts();
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const updateEthWallet = async () => {
    setIsUpdatingEth(true);
    try {
      await updateAccount(moonAccount.account.id, { wlWallet });

      const message = `ETH Wallet has been updated`;
      addToast(message, {
        appearance: "info",
        autoDismiss: true,
      });
    } catch (error) {
      setWlWallet(moonAccount.account.wlWallet);
      addToast(error.reason ? error.reason : error.message, {
        appearance: "error",
        autoDismiss: true,
      });
    }
    setIsUpdatingEth(false);
  };

  const updateBtcOrdinalWallet = async () => {
    setIsUpdatingBtcOrdinal(true);
    try {
      await updateAccount(moonAccount.account.id, { btcOrdinalWallet });

      const message = `BTC Ordinal Wallet has been updated`;
      addToast(message, {
        appearance: "info",
        autoDismiss: true,
      });
    } catch (error) {
      setWlWallet(moonAccount.account.btcOrdinalWallet);
      addToast(error.reason ? error.reason : error.message, {
        appearance: "error",
        autoDismiss: true,
      });
    }
    setIsUpdatingBtcOrdinal(false);
  };

  return (
    <Card>
      <CardContent style={{ padding: "2.8em 2.2em" }}>
        <Container>
          <Row>
            <Col style={{ marginBottom: "20px" }}>
              <h2>WL Wallet Address</h2>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <h5 className="mb-2">
                Your current wallet address you wish to use for whitelist
              </h5>
            </Col>
          </Row>
          <Row className="justify-content-start">
            <Col md={8}>
              <div>
                <Form.Group className="mb-2" controlId="formBasicEmail">
                  <h5 className="mb-2 mt-4">
                    <b>ETH</b>
                  </h5>
                  <Form.Control
                    placeholder="Enter WL Wallet Address"
                    value={wlWallet}
                    onChange={({ target: { value } }) => setWlWallet(value)}
                    disabled={isUpdatingEth}
                    type="text"
                    style={{
                      fontSize: "24px",
                      fontWeight: "600",
                      borderRadius: "8px",
                      border: "1px solid black",
                    }}
                  />
                </Form.Group>
                <Button
                  onClick={updateEthWallet}
                  disabled={isUpdatingEth}
                  size="sm"
                >
                  {isUpdatingEth ? (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                      style={{
                        marginRight: "7px",
                        marginTop: "-5px",
                        position: "relative",
                        top: "-2px",
                      }}
                    />
                  ) : null}
                  {isUpdatingEth ? "Updating..." : "Update"}
                </Button>
              </div>
            </Col>
            <Col md={8}>
              <div>
                <Form.Group className="mb-2 mt-4" controlId="formBasicEmail">
                  <h5 className="mb-2">
                    <b>BTC Ordinal</b>
                  </h5>
                  <Form.Control
                    placeholder="Enter WL Wallet Address"
                    value={btcOrdinalWallet}
                    onChange={({ target: { value } }) =>
                      setBtcOrdinalWallet(value)
                    }
                    disabled={isUpdatingBtcOrdinal}
                    type="text"
                    style={{
                      fontSize: "24px",
                      fontWeight: "600",
                      borderRadius: "8px",
                      border: "1px solid black",
                    }}
                  />
                </Form.Group>
                <Button
                  onClick={updateBtcOrdinalWallet}
                  disabled={isUpdatingBtcOrdinal}
                  size="sm"
                >
                  {isUpdatingBtcOrdinal ? (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                      style={{
                        marginRight: "7px",
                        marginTop: "-5px",
                        position: "relative",
                        top: "-2px",
                      }}
                    />
                  ) : null}
                  {isUpdatingBtcOrdinal ? "Updating..." : "Update"}
                </Button>
              </div>
            </Col>
          </Row>
        </Container>
      </CardContent>
    </Card>
  );
}

export default WLWalletComponent;
