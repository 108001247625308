import { grey } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';

const pinkColor = 'rgb(255, 32, 121)';
const purpleColor = 'rgb(68, 11, 212)';

export const theme = (mode) => createTheme({
    palette: {
        mode: mode ?? 'light',
        ...((mode ?? 'light') === 'light'
        ?   {
                primary: {
                    main: purpleColor
                },
                secondary: {
                    main: pinkColor
                },
                background: {
                    shade: '#00000020'
                }
            }
        :   {
                primary: {
                    main: pinkColor
                },
                secondary: {
                    main: purpleColor
                },
                background: {
                    shade: '#FFFFFF20'
                }
            } 
        )
    },
    typography: {
        fontFamily: [
            'Montserrat',
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        counterLabel: {
            fontSize: 9,
            fontWeight: 600,
            color: grey[600]
        },
        largeCounterLabel: {
            fontSize: 14,
            fontWeight: 600,
            color: grey[700]
        },
        counterCaption: {
            fontSize: 10,
            fontWeight: 600,
            color: grey[700]
        },
    },
    components: {
        MuiTextField: {
            defaultProps: {
                fullWidth: true,
                margin: 'normal'
            },
        },
        MuiFilledInput: {
            styleOverrides: {
                root: {
                    background: 'rgb(232, 241, 250)',
                }
            }
        },
        MuiCard: {
            defaultProps: {
                raised: false,
                elevation: 0,
            },
            styleOverrides: {
                root: {
                    borderRadius: '0.6em'
                }
            }
        },
        MuiCardContent: {
            styleOverrides: {
                root: {
                    padding: "2em"
                }
            }
        },
        MuiCardActions: {
            styleOverrides: {
                root: {
                    padding: "2.2em 2em"
                }
            }
        },
        MuiFormControl: {
            defaultProps: {
                fullWidth: true
            }
        },
        MuiFormGroup: {
            defaultProps: {
                margin: 'normal'
            }
        },
        MuiToggleButtonGroup: {
            defaultProps: {
                fullWidth: true,
                color: 'primary'
            }
        },
        MuiTablePagination: {
            styleOverrides: {
                selectLabel: {
                    marginTop: "1em"
                },
                input: {
                    border: "1px solid #dddddd",
                    borderRadius: 6
                },
                displayedRows: {
                    marginTop: "1em"
                }
            }
        }
    },
});
