import React, { useState, useContext } from "react";
import { Button, Container } from "react-bootstrap";
import { 
  Card, 
  CardContent, 
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Pagination,
  Stack, 
} from "@mui/material";
import JoinNowModal from '../JoinNowModal';
import CampaignRow from "./CampaignRow";

import { MoonAccountContext } from "../../providers/MoonAccountProvider";
import { accountTypes } from "../../enums/accountTypes";
import { Link } from "react-router-dom";

function CampaignListComponent({ campaigns, totalPages, onChangePage }) {
  const [modalState, setModalState] = useState(false);
  const moonAccount = useContext(MoonAccountContext);

  const toggleModal = () => {
    setModalState(!modalState);
  }

  return (
    <>
      {campaigns && (
        <>
          <JoinNowModal open={modalState} onClose={toggleModal} />
          <Stack spacing={4}>
            <TableContainer>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>
                    </TableCell>
                    <TableCell>
                      <b>Campaign</b>
                    </TableCell>
                    <TableCell align="center">
                      <b>Type</b>
                    </TableCell>
                    <TableCell align="center">
                      <b>Status</b>
                    </TableCell>
                    <TableCell align="center">
                      <b>Winners</b>
                    </TableCell>
                    <TableCell align="center">
                      <b>Price Per Entry</b>
                    </TableCell>
                    <TableCell align="center">
                      <Stack>
                        <div><b>Entries</b></div>
                        <div className="text-muted"><small><b>yours</b> / total</small></div>
                      </Stack>
                    </TableCell>
                    <TableCell align="center">
                    </TableCell>
                    <TableCell align="right">
                    {(moonAccount?.account?.type === accountTypes.admin) && 
                      <Link to="/campaigns/new">
                        <Button variant="outline-dark" size="sm">
                          New Campaign
                        </Button>
                      </Link>}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {campaigns.map((campaign) => (
                    <CampaignRow campaign={campaign} key={campaign._id} />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Grid container justifyContent="center" >
              <Pagination count={totalPages} onChange={onChangePage} />
            </Grid>
          </Stack>
        </>
      )}
    </>
  );
}

export default CampaignListComponent;