import { SERVER_ID } from "../config/constant";
import {
  dropTypes,
  dropTypesLabel,
  chains,
  chainsLabel,
} from "../enums/dropTypes";
import { DateTime } from "luxon";

import * as Yup from "yup";
import { InputAdornment } from "@mui/material";
import { campaignStatuses } from "../enums/campaignStatuses";
import { binomial } from "../util/math";

const dropTypeOptionArray = [
  { label: dropTypesLabel.fcfs, value: dropTypes.fcfs },
  { label: dropTypesLabel.raffle, value: dropTypes.raffle },
  { label: dropTypesLabel.dutch, value: dropTypes.dutch },
];

const chaindropTypeOptionArray = [
  { label: chainsLabel.eth, value: chains.eth },
  { label: chainsLabel.btc, value: chains.btc },
];

export const form = (startDt) => {
  return {
    initialValues: {
      dropType: dropTypes.fcfs,
      wlAvailable: 2,
      pricePerEntry: 10,
      requireWallet: true,
      requireDiscord: false,
      pin: false,
      requireTwitter: false,
      serverId: SERVER_ID,
      mintCurrency: "ETH",
      chain: "ETH",
      mintDate: null,
      mintTime: undefined,
      startTime: startDt,
    },
    formInputs: [
      {
        label: "Drop Type",
        name: "dropType",
        placeholder: "Drop Type",
        type: "toggle",
        options: dropTypeOptionArray,
        required: true,
      },
      {
        label: "Chain",
        name: "chain",
        placeholder: "Chain",
        type: "toggle",
        options: chaindropTypeOptionArray,
        required: true,
      },
      {
        label: "Campaign Name",
        name: "projectName",
        placeholder: "Campaign Name",
        type: "text",
        required: true,
      },
      {
        label: "Description",
        name: "description",
        placeholder: "Description",
        type: "textarea",
      },
      {
        label: "Thumbnail Image Url",
        name: "thumbnailImageUrl",
        placeholder: "Thumbnail Image Url",
        type: "url",
      },
      {
        label: "Image Url",
        name: "imageUrl",
        placeholder: "Image Url",
        type: "url",
      },
      // {
      //     label: 'Footer',
      //     name: 'footerText',
      //     placeholder: 'Footer',
      //     type: 'textarea',
      // },
      {
        type: "divider",
      },
      {
        label: "Start Date & Time",
        name: "startTime",
        placeholder: "Start Date & Time",
        type: "datetime",
        minDateTime: startDt,
        helperText: "Timezone: UTC Date & Time",
        // required: true,
        // disabled: true,
      },
      {
        label: "WL Available",
        name: "wlAvailable",
        placeholder: "WL Available",
        type: "number",
        inputProps: {
          inputMode: "numeric",
          pattern: "[0-9]*",
          min: 0,
        },
        required: true,
        min: 0,
        sm: 6,
      },
      {
        label: "Price Per Entry",
        name: "pricePerEntry",
        placeholder: "Price Per Entry",
        type: "number",
        dependOn: "dropType",
        dependOnValues: [dropTypes.fcfs, dropTypes.raffle],
        required: true,
        InputProps: {
          endAdornment: <InputAdornment position="end">$MOON</InputAdornment>,
        },
        inputProps: {
          inputMode: "numeric",
          pattern: "[0-9]*",
          min: 0,
        },
        min: 0,
        sm: 6,
      },
      {
        label: "Duration",
        name: "durationHours",
        placeholder: "Duration",
        type: "number",
        dependOn: "dropType",
        dependOnValues: [dropTypes.raffle],
        InputProps: {
          endAdornment: <InputAdornment position="end">Hours</InputAdornment>,
        },
        inputProps: {
          inputMode: "numeric",
          pattern: "[0-9]*",
          min: 0,
        },
        min: 0,
        required: true,
        sm: 6,
      },
      {
        label: "Max User Entries",
        name: "maxUserEntries",
        placeholder: "Max User Entries",
        type: "select",
        options: [
          { label: "1", value: 1 },
          { label: "25", value: 25 },
        ],
        dependOn: "dropType",
        dependOnValues: [dropTypes.raffle],
        required: true,
        sm: 6,
      },
      {
        label: "Starting Price",
        name: "pricePerEntry",
        placeholder: "Starting Price",
        type: "number",
        dependOn: "dropType",
        dependOnValues: [dropTypes.dutch],
        required: true,
        InputProps: {
          endAdornment: <InputAdornment position="end">$MOON</InputAdornment>,
        },
        inputProps: {
          inputMode: "numeric",
          pattern: "[0-9]*",
          min: 0,
        },
        min: 0,
        sm: 6,
      },
      {
        label: "Ending Price",
        name: "endingPrice",
        placeholder: "Ending Price",
        type: "number",
        dependOn: "dropType",
        dependOnValues: [dropTypes.dutch],
        required: true,
        InputProps: {
          endAdornment: <InputAdornment position="end">$MOON</InputAdornment>,
        },
        inputProps: {
          inputMode: "numeric",
          pattern: "[0-9]*",
          min: 0,
        },
        min: 0,
        sm: 6,
      },
      {
        label: "Decrease Interval",
        name: "decreaseInterval",
        placeholder: "Decrease Interval",
        type: "number",
        dependOn: "dropType",
        dependOnValues: [dropTypes.dutch],
        required: true,
        InputProps: {
          endAdornment: <InputAdornment position="end">Minutes</InputAdornment>,
        },
        inputProps: {
          inputMode: "numeric",
          pattern: "[0-9]*",
          min: 0,
        },
        min: 0,
        sm: 6,
      },
      {
        label: "Decrease Per Interval",
        name: "decreasePerInterval",
        placeholder: "Decrease Per Interval",
        type: "number",
        dependOn: "dropType",
        dependOnValues: [dropTypes.dutch],
        required: true,
        InputProps: {
          endAdornment: <InputAdornment position="end">$MOON</InputAdornment>,
        },
        inputProps: {
          inputMode: "numeric",
          pattern: "[0-9]*",
          min: 0,
        },
        min: 0,
        sm: 6,
      },
      {
        label: "Mint Date",
        name: "mintDate",
        placeholder: "Mint Date",
        type: "date",
        helperText: "Timezone: UTC Date & Time",
        sm: 6,
      },
      {
        label: "Mint Time",
        name: "mintTime",
        placeholder: "Mint Time",
        type: "time",
        helperText: "Timezone: UTC Date & Time",
        sm: 6,
      },
      {
        label: "Mint Price",
        name: "mintPrice",
        placeholder: "Mint Price",
        type: "number",
        inputProps: {
          inputMode: "numeric",
          pattern: "[0-9]*",
          min: 0,
        },
        sm: 6,
      },
      {
        label: "Mint Currency",
        name: "mintCurrency",
        placeholder: "Mint Currency",
        type: "text",
        required: true,
        sm: 6,
      },
      {
        label: "Total Supply",
        name: "totalSupply",
        placeholder: "Total Supply",
        type: "number",
        InputProps: {
          endAdornment: <InputAdornment position="end">$MOON</InputAdornment>,
        },
        inputProps: {
          inputMode: "numeric",
          pattern: "[0-9]*",
          min: 0,
        },
        min: 0,
        sm: 6,
      },
      {
        label: "Discord Link",
        name: "discordUrl",
        placeholder: "Discord Link",
        type: "url",
        /*dependOn: 'requireDiscord',
        required: true*/
      },
      {
        label: "Twitter",
        name: "twitterUrl",
        placeholder: "Twitter",
        type: "url",
      },
      {
        label: "Website",
        name: "websiteUrl",
        placeholder: "Website",
        type: "url",
      },
      {
        label: "WL Instruction",
        name: "instructions",
        placeholder: "WL Instruction",
        type: "textarea",
      },
      {
        type: "divider",
      },
      {
        label: "Require Wallet",
        name: "requireWallet",
        placeholder: "Require Wallet",
        type: "switch",
        sm: 6,
      },
      {
        label: "Pin",
        name: "pin",
        placeholder: "Pin",
        type: "switch",
        sm: 6,
      },
      {
        label: "Require Discord",
        name: "requireDiscord",
        placeholder: "requireDiscord",
        type: "switch",
      },
      {
        label: "Discord Server Id",
        name: "discordId",
        placeholder: "Discord Server Id",
        type: "text",
        dependOn: "requireDiscord",
        required: true,
        sm: 6,
      },
      {
        label: "Discord Server Name",
        name: "discordServerName",
        placeholder: "Discord Server Name",
        type: "text",
        dependOn: "requireDiscord",
        required: true,
        sm: 6,
      },
      {
        label: "Discord Role ID",
        name: "discordRoleId",
        placeholder: "Discord Role ID",
        type: "text",
        dependOn: "requireDiscord",
        required: false,
        sm: 6,
      },
      {
        label: "Discord Role Name",
        name: "discordRoleName",
        placeholder: "Discord Role Name",
        type: "text",
        dependOn: "requireDiscord",
        required: false,
        sm: 6,
      },
      {
        label: "Require Twitter Following",
        name: "requireTwitter",
        placeholder: "requireTwitter",
        type: "switch",
      },
      {
        label: "Twitter Screen Name",
        name: "twitterScreenName",
        placeholder: "Twitter Screen Name",
        type: "text",
        dependOn: "requireTwitter",
        helperText: "Example. @WhaleTogether",
        required: true,
      },
      {
        label: "Require ETH Balance",
        name: "requireEthBalance",
        placeholder: "requireEthBalance",
        type: "switch",
      },
      {
        label: "ETH Balance Min.",
        name: "ethBalanceMin",
        placeholder: "ETH Balance Min.",
        type: "number",
        dependOn: "requireEthBalance",
        required: true,
      },
      {
        type: "divider",
      },
      {
        label: "Server Id",
        name: "serverId",
        placeholder: "serverId",
        type: "text",
        disabled: true,
        required: true,
        sm: true,
      },
      {
        label: "Discord Channel",
        name: "channelId",
        placeholder: "Discord Channel",
        type: "text",
        required: true,
        sm: true,
      },
    ],
    makeValidationSchema: (inputs) =>
      inputs.reduce((map, obj) => {
        let yupChain =
          obj.type === "datetime"
            ? Yup.date("Invalid Date Time")
            : obj.type === "date"
            ? Yup.date("Invalid Date")
            : obj.type === "number"
            ? Yup.number("Invalid Number")
            : obj.type === "url"
            ? Yup.string().url("Invalid Url")
            : Yup.string();

        if (obj.required) {
          yupChain = yupChain.required(`*${obj.label} is required`);
        } else {
          yupChain = yupChain.nullable(true);
        }

        if (obj.dependOn) {
          let yupWrapper =
            obj.type === "datetime"
              ? Yup.date("Invalid Date Time")
              : obj.type === "date"
              ? Yup.date("Invalid Date")
              : obj.type === "number"
              ? Yup.number("Invalid Number")
              : obj.type === "url"
              ? Yup.string().url("Invalid Url")
              : Yup.string();
          if (obj.dependOnValues && Array.isArray(obj.dependOnValues)) {
            yupWrapper = yupWrapper.when(obj.dependOn, {
              is: obj.dependOnValues[0],
              then: yupChain,
            });
          } else {
            yupWrapper = yupWrapper.when(obj.dependOn, {
              is: true,
              then: yupChain,
            });
          }

          map[obj.name] = yupWrapper;
        } else {
          map[obj.name] = yupChain;
        }
        return map;
      }, {}),
  };
};

export function calculateDutchMetaData({
  startTime,
  decreaseInterval,
  decreasePerInterval,
  pricePerEntry: startingPrice,
  endingPrice,
}) {
  const start = DateTime.fromISO(startTime);
  const now = DateTime.local();
  const diffInMilliseconds = now.diff(start).toObject().milliseconds;
  const intervalRemainingMilliseconds =
    decreaseInterval - (diffInMilliseconds % decreaseInterval);
  const intervalRemainingSeconds = intervalRemainingMilliseconds / 1000;
  const intervalRemainingMinutes = intervalRemainingSeconds / 60;
  const intervalRemainingPercentage =
    (intervalRemainingMilliseconds * 100) / decreaseInterval;
  const intervalRemaining =
    Math.abs(intervalRemainingMinutes) >= 1
      ? `${Math.ceil(intervalRemainingMinutes)}m`
      : `${Math.ceil(intervalRemainingSeconds)}s`;
  const intervalPassed = Math.floor(diffInMilliseconds / decreaseInterval);
  const calculatedPrice = startingPrice - intervalPassed * decreasePerInterval;
  return {
    intervalRemainingMilliseconds,
    intervalRemainingSeconds,
    intervalRemainingMinutes,
    intervalRemainingPercentage,
    intervalRemaining,
    currentPrice: calculatedPrice > endingPrice ? calculatedPrice : endingPrice,
    started: now > start,
  };
}

export function calculateRaffleMetaData({ startTime, durationMs }) {
  const start = DateTime.fromISO(startTime);
  const end = DateTime.fromISO(startTime).plus({ milliseconds: durationMs });
  const now = DateTime.local();
  const remainingMilliseconds = -now.diff(end).toObject().milliseconds;
  const remainingSeconds = remainingMilliseconds / 1000;
  const remainingMinutes = remainingSeconds / 60;
  const remainingHours = remainingMinutes / 60;
  const remainingPercentage = (remainingMilliseconds * 100) / durationMs;
  const remaining =
    Math.abs(remainingHours) >= 1
      ? `${Math.ceil(remainingHours * 10) / 10}h`
      : Math.abs(remainingMinutes) >= 1
      ? `${Math.ceil(remainingMinutes)}m`
      : `${Math.ceil(remainingSeconds)}s`;
  return {
    remainingMilliseconds,
    remainingSeconds,
    remainingMinutes,
    remainingHours,
    remainingPercentage,
    remaining,
    started: now > start,
    ended: now > end,
  };
}

export function checkIfStarted({ startTime }) {
  const start = DateTime.fromISO(startTime);
  const now = DateTime.local();
  return {
    started: now > start,
  };
}

export function getStartsIn({ startTime }) {
  const start = DateTime.fromISO(startTime);
  const now = DateTime.local();
  const milliseconds = start.diff(now).toObject().milliseconds;
  const seconds = milliseconds / 1000;
  const minutes = seconds / 60;
  const hours = minutes / 60;
  return {
    seconds,
    minutes,
    hours,
    label:
      Math.abs(hours) >= 1
        ? `${Math.ceil(hours * 10) / 10}h`
        : Math.abs(minutes) >= 1
        ? `${Math.ceil(minutes)}m`
        : `${Math.ceil(seconds)}s`,
    percentage: Math.abs(minutes) >= 1 ? 100 : (seconds / 60) * 100,
  };
}

export const statusText = {
  scheduled: "Scheduled",
  active: "Live",
  completed: "Ended",
};

export const statusColor = {
  scheduled: "info",
  active: "success",
  completed: "default",
};

export function getLiveStatus(campaign) {
  if (campaign.completed || campaign.status === campaignStatuses.completed) {
    return campaignStatuses.completed;
  }
  switch (campaign.dropType) {
    case dropTypes.dutch:
      const { started: ducthStarted } = calculateDutchMetaData(campaign);
      return !ducthStarted
        ? campaignStatuses.scheduled
        : campaign.status !== campaignStatuses.scheduled
        ? campaign.status
        : campaignStatuses.active;
    case dropTypes.raffle:
      const { started: raffleStarted, ended: raffleEnded } =
        calculateRaffleMetaData(campaign);
      return !raffleStarted
        ? campaignStatuses.scheduled
        : raffleEnded
        ? campaignStatuses.completed
        : campaign.status !== campaignStatuses.scheduled
        ? campaign.status
        : campaignStatuses.active;
    default:
      const { started } = checkIfStarted(campaign);
      return !started ? campaignStatuses.scheduled : campaignStatuses.active;
  }
}

export function getTotalEntries({ entries }) {
  return entries?.length ?? 0;
}

export function getEntriesByMoonAccount({ entries }, moonAccount) {
  return entries.reduce(
    (p, entry) => p + (moonAccount?.account?.discordId === entry.id ? 1 : 0),
    0,
  );
}

export function getProbablityOfWinning(campaign, moonAccount) {
  const { wlAvailable: winningSpots } = campaign;
  const totalSpots =
    getTotalEntries(campaign) > winningSpots
      ? getTotalEntries(campaign)
      : winningSpots;
  const accountEntries = getEntriesByMoonAccount(campaign, moonAccount);
  const probability =
    1 -
    binomial(totalSpots - accountEntries, winningSpots) /
      binomial(totalSpots, winningSpots);
  return probability.toFixed(4);
}
