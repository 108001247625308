import React from "react";

import CampaignsSection from "../components/CampaignsSection";

function CampaignsPage(props) {
  return (
    <CampaignsSection
      textColor="white"
      variant="dark"
      title="Campaigns"
      subtitle=""
    />
  );
}

export default CampaignsPage;
