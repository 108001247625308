import React from "react";
import ReactDom from "react-dom";
import App from "./pages/_app";
import ShopProvider from "./providers/ShopProvider";
import * as serviceWorker from "./serviceWorker";

ReactDom.render(
  <ShopProvider>
    <App />
  </ShopProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
